const ViewMode = {
  LIST: "list",
  PROFILE: "profile"
};

export const sidePanelStore = {
  namespaced: true,
  state: {
    active: false,
    selectedPersonId: null,
    peopleList: [],
    viewMode: ViewMode.PROFILE,
    heading: "",
    showBackButton: false
  },
  mutations: {
    setActive(state, isActive) {
      state.active = isActive;
    },
    setSelectedPersonId(state, personId) {
      state.selectedPersonId = personId;
    },
    setPeopleList(state, list) {
      state.peopleList = list;
    },
    setViewMode(state, mode) {
      state.viewMode = mode;
    },
    setHeading(state, heading) {
      state.heading = heading;
    },
    setShowBackButton(state, show) {
      state.showBackButton = show;
    }
  },
  actions: {
    open({ commit }, payload) {
      if (Array.isArray(payload.people)) {
        commit("setSelectedPersonId", null);
        commit("setPeopleList", payload.people);
        commit("setViewMode", ViewMode.LIST);
        commit("setHeading", payload.heading || "");
        commit("setShowBackButton", false);
      } else {
        commit("setSelectedPersonId", payload);
        commit("setViewMode", ViewMode.PROFILE);
        commit("setShowBackButton", false);
      }

      commit("setActive", true);
    },
    showSinglePerson({ commit }, personId) {
      commit("setSelectedPersonId", personId);
      commit("setViewMode", ViewMode.PROFILE);
      commit("setShowBackButton", true);
    },
    goBackToList({ commit }) {
      commit("setSelectedPersonId", null);
      commit("setViewMode", ViewMode.LIST);
      commit("setShowBackButton", false);
    },
    close({ commit }) {
      commit("setActive", false);
    },
    reset({ commit }) {
      commit("setSelectedPersonId", null);
      commit("setActive", false);
      commit("setPeopleList", []);
      commit("setViewMode", ViewMode.PROFILE);
      commit("setHeading", null);
      commit("setShowBackButton", false);
    }
  },
  getters: {
    active: state => state.active,
    selectedPersonId: state => state.selectedPersonId,
    peopleList: state => state.peopleList,
    viewMode: state => state.viewMode,
    showBackButton: state => state.showBackButton,
    heading: state => state.heading
  }
};
