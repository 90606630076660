<template>
  <div>
    <incomplete-assessment-reminder-dialog
      :users="usersWithIncompleteAssessments()"
      :show-dialog="showIncompleteAssessmentReminderDialog"
      @update:showDialog="showIncompleteAssessmentReminderDialog = $event"
    />
    <assessment-invite-dialog
      :users="users"
      :emailInvitePurpose="emailInvitePurpose"
      :show-dialog="showAssessmentInviteDialog"
      @update:showDialog="showAssessmentInviteDialog = $event"
    />
    <v-row align="center">
      <v-col cols="auto" class="pl-2">
        <h1 class="text-h5">
          {{ $t("dashboardHistoryComponents.assessmentHistoryTable.title") }}
        </h1>
      </v-col>
      <v-col cols="auto">
        <ai-search-toggle
          v-if="!hideAiFeatures"
          v-model="aiModeIsActive"
          :loading="loading"
          @aiSearchSettingsChanged="handleAiSearchSettingsChanged"
        />
      </v-col>
      <v-col cols="auto" class="ml-auto" v-if="canExportAssessmentData">
        <ExportToCSV :data="assessmentsForCSV" class="ml-auto" />
      </v-col>
    </v-row>

    <div v-if="aiModeIsActive && !loading" class="mb-8">
      <ai-search
        @onSearch="applyAiSearch"
        @onReset="handleAiSearchReset"
        @abort="stopAiSearch"
        class="mt-8"
        :loading="aiQueryRunning"
        :can-reset="!!aiResponse?.text"
        ref="aiSearch"
      />
      <ai-search-suggestions
        v-if="!aiQueryRunning"
        @onSearch="onSearchSuggestion"
        class="mx-0"
      />
      <ai-search-response
        v-if="aiResponse?.text || aiQueryResponsePending"
        :response="aiResponse"
        :loading="aiQueryResponsePending"
        :class="{
          'mt-3': !aiQueryResponsePending
        }"
      />
    </div>

    <filter-bar
      v-show="!aiModeIsActive"
      :users="users"
      @update="filterUsers"
      @noHomeAssessmentFilter="filterByNoCompletedHomeAssessment()"
      @noOfficeAssessmentFilter="filterByNoCompletedOfficeAssessment()"
      @homeAndOfficeAssessmentFilter="
        filterByCompletedHomeAndOfficeAssessment()
      "
      @noCompletedDeskAssessmentFilter="
        filterByNoCompletedDeskAssessmentCompleted()
      "
      @noSentDeskAssessmentFilter="filterByNoSentDeskAssessment()"
      @hasHealthConditionFilter="filterByHasHealthCondition()"
      @needsAttentionFilter="filterByNeedsAttention()"
      @noFilter="clearAdvancedFilter()"
      @isAdvancedSearch="setAdvancedSearch($event)"
      class="mt-4 mb-8"
      :preloadedEmail="preloadedEmail"
      ref="filterBar"
    />
    <div style="position: relative">
      <vitrue-new-feature
        v-if="showNewHistoryTableTooltip"
        v-model="tooltip"
        :text="$t('tooltips.newFeatures.newHistoryTable')"
        :width="$vuetify.breakpoint.smAndDown ? 250 : 500"
        @close="tooltip = false"
        position="top"
        id="HistoryTableV2"
      />
      <v-data-table
        :headers="userHeaders"
        :items="users"
        item-key="email"
        :custom-sort="customSort"
        :loading="loading"
        @click:row="handleRowClick($event)"
        :items-per-page="20"
        :footer-props="{ 'items-per-page-options': [20, 50, 100] }"
      >
        <template v-slot:item.icon="{ item }">
          <avatar-icon
            :text="item.name"
            :fallback-text="item.email"
            :size="30"
          />
        </template>
        <template v-slot:item.name="{ item, index }">
          <v-hover v-slot="{ hover }" open-delay="200">
            <div
              id="emailContainer"
              class="my-2"
              :style="`width: ${emailColumnWidth}`"
            >
              <user-conditions-label
                v-if="item.name"
                title
                :label="item.name"
                :conditions="item.healthConditions"
                :userId="item.id"
              />
              <user-conditions-label
                :title="!item.name"
                :label="item.email"
                :hideConditionsFlag="!!item.name"
                :conditions="item.healthConditions"
                :userId="item.id"
              />
            </div>
          </v-hover>
        </template>
        <template v-slot:item.assessmentOverview="{ item }">
          <v-row
            :justify="$vuetify.breakpoint.xs ? 'end' : 'start'"
            :class="$vuetify.breakpoint.xs ? '' : 'singleLine'"
          >
            <v-col cols="12" sm="8" align-self="center">
              <v-tooltip
                bottom
                content-class="text-center"
                :disabled="
                  getCompletedAssessmentCounts(item.assessments)
                    .completeCount === 0
                "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-row
                    v-on="on"
                    v-bind="attrs"
                    :justify="$vuetify.breakpoint.xs ? 'end' : 'start'"
                    no-gutters
                    :style="
                      getCompletedAssessmentCounts(item.assessments)
                        .completeCount > 0
                        ? 'cursor: pointer'
                        : ''
                    "
                  >
                    <v-col cols="auto" class="mr-3" align-self="center">
                      <v-progress-circular
                        id="completeAssessmentProgress"
                        :rotate="270"
                        :size="20"
                        :value="
                          getCompletedAssessmentCounts(item.assessments)
                            .percentage
                        "
                        :width="5"
                        color="#90C819"
                      />
                    </v-col>
                    <v-col cols="auto" class="text-caption black--text">
                      <div>
                        {{
                          $t(
                            "dashboardHistoryComponents.userHistoryTable.assessmentsComplete",
                            {
                              0: getCompletedAssessmentCounts(item.assessments)
                                .completeCount
                            }
                          )
                        }}
                      </div>
                      <div class="grey--text incompleteCount">
                        {{
                          $t(
                            "dashboardHistoryComponents.userHistoryTable.assessmentsIncomplete",
                            {
                              0: getCompletedAssessmentCounts(item.assessments)
                                .incompleteCount
                            }
                          )
                        }}
                      </div>
                    </v-col>
                  </v-row>
                </template>
                <div
                  v-for="location in [locations.home, locations.office]"
                  :key="location.value"
                >
                  <p class="mb-0">
                    <v-icon small color="white">
                      {{ location.icon }}
                    </v-icon>
                    {{
                      $t(location.completedAtText, {
                        0: numberCompletedAtLocation(
                          item.assessments,
                          location.value
                        )
                      })
                    }}
                  </p>
                </div>
              </v-tooltip>
            </v-col>
            <v-col
              v-if="
                !anonymousResults && item.risks && getAllRisks(item).length > 0
              "
              cols="auto"
              align-self="center"
            >
              <v-tooltip bottom content-class="text-center">
                <template v-slot:activator="{ on, attrs }">
                  <p v-on="on" v-bind="attrs" class="mb-0">
                    <v-icon>mdi-list-box-outline</v-icon>
                    {{ getRiskText(item) }}
                  </p>
                </template>
                <div
                  v-for="location in [locations.office, locations.home]"
                  :key="location.value"
                >
                  <div
                    v-if="showLocationRisks(item, location.value)"
                    class="text-start my-2"
                  >
                    <v-icon dark class="mr-2">{{ location.icon }}</v-icon
                    >{{ $t(location.text) }}
                    <ul>
                      <li
                        v-for="r in getHomeOfficeRisks(item)[location.value]"
                        :key="r"
                      >
                        {{ $t(`suggestionsPage.table.issues.${r}`) }}
                      </li>
                    </ul>
                  </div>
                </div>
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.tags="{ item }">
          <div
            v-if="item.tags.length > 0"
            style="max-width: 500px; min-width: 100px"
          >
            <v-chip
              id="userTag"
              v-for="tag in getUniqueSortedTags(item.tags).slice(0, 3)"
              :key="tag"
              class="ma-1"
              small
              :color="tagColors[tag]"
              outlined
            >
              {{ tag }}
            </v-chip>
            <v-tooltip
              open-on-click
              :open-on-hover="false"
              content-class="text-center"
              max-width="300"
              color="vitruePaleGrey"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-chip
                  v-on="on"
                  v-bind="attrs"
                  v-if="item.tags.length > 3"
                  small
                  outlined
                  rounded="md"
                >
                  ...
                </v-chip>
              </template>
              <div>
                <v-row no-gutters>
                  <v-col
                    v-for="tag in getUniqueSortedTags(item.tags)"
                    :key="tag"
                    cols="auto"
                  >
                    <v-chip
                      id="userTag"
                      class="ma-1"
                      small
                      :color="tagColors[tag]"
                      dark
                      outlined
                    >
                      {{ tag }}
                    </v-chip>
                  </v-col>
                </v-row>
              </div>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:item.latestAssessmentUpdate="{ item }">
          <div id="latestAssessmentUpdate" class="black--text">
            {{
              item.assessments.length > 0
                ? getLatestAssessmentDate(item).toLocaleDateString()
                : "-"
            }}
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            x-small
            outlined
            color="primary"
            @click.stop="$emit('sendInvite', item)"
          >
            <v-icon x-small class="mr-2">mdi-email-outline</v-icon>
            {{ $t("dashboardHistoryComponents.userHistoryTable.sendNew") }}
          </v-btn>
        </template>
      </v-data-table>
    </div>
    <v-row
      justify="center"
      v-if="canSendAssessmentInvitations && isAdvancedSearch && !disableEmails"
    >
      <v-col cols="12" sm="8" md="6" lg="5">
        <v-btn
          color="primary"
          width="100%"
          @click="openAssessmentInviteDialog()"
          :disabled="!canAdvancedSearchInvites"
        >
          {{ advancedInviteButtonText }}
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
        v-if="canSendIncompleteAssessmentReminders && !disableEmails"
      >
        <v-btn
          color="primary"
          width="100%"
          @click="openIncompleteAssessmentReminderDialog()"
        >
          {{
            $t(
              "dashboardHistoryComponents.userHistoryTable.remindXAssessments",
              { 0: usersWithIncompleteAssessments().length }
            )
          }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center" v-else-if="!disableEmails">
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-btn
          color="primary"
          width="100%"
          @click="showAssessmentInviteDialog = true"
          :disabled="!canSendAssessmentInvitations"
        >
          {{
            $t(
              "dashboardHistoryComponents.userHistoryTable.inviteXAssessments",
              { 0: users.length }
            )
          }}
        </v-btn>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-btn
          color="primary"
          width="100%"
          @click="showIncompleteAssessmentReminderDialog = true"
          :disabled="!canSendIncompleteAssessmentReminders"
        >
          {{
            $t(
              "dashboardHistoryComponents.userHistoryTable.remindXAssessments",
              { 0: usersWithIncompleteAssessments().length }
            )
          }}
        </v-btn>
      </v-col>
    </v-row>
    <v-snackbar v-model="errorMessage.show" timeout="2500" color="error">
      {{ errorMessage.text }}
    </v-snackbar>
  </div>
</template>

<script>
const preloadErrorMessagePath =
  "dashboardHistoryComponents.userHistoryTable.preloadError";
import AvatarIcon from "@/components/common/AvatarIcon.vue";
import FilterBar from "./FilterBar.vue";
import {
  AssessmentStateCodes,
  Locations
} from "./assessmentHistoryConstants.js";
import {
  filterUsers,
  filterUsersWithoutCompletedAssessmentInEnvironment,
  filterUsersWithCompletedHomeAndOfficeAssessments,
  filterUsersWithoutCompletedDeskAssessments,
  filterUsersWithoutAnyDeskAssessments,
  getHomeOfficeRisks,
  getLatestAssessmentDate,
  getPreloadedUserAssessment,
  filterUsersWithAHealthCondition,
  filterUsersWhoNeedsAttention,
  filterUsersWhoNeedsAttention30Days
} from "./userHistoryFilterService.js";
import { getUserAssessmentHistory } from "@/customApi";
import VitrueNewFeature from "@/components/common/VitrueNewFeature.vue";
import NewFeatureService from "@/services/new-feature-service.js";
import IncompleteAssessmentReminderDialog from "@/components/common/assessment-history/IncompleteAssessmentReminderDialog.vue";
import AssessmentInviteDialog from "@/components/common/assessment-history/AssessmentInviteDialog.vue";
import { mapGetters, mapMutations, mapState } from "vuex";
import UserConditionsLabel from "./UserConditionsLabel.vue";
import ExportToCSV from "@/components/common/assessment-history/ExportToCSV.vue";
import { getDeskAssessmentsInCSVFormat } from "@/components/common/assessment-history/userAssessmentService.js";
import AiSearch from "@/components/common/assessment-history/AiSearch.vue";
import AiSearchToggle from "@/components/common/assessment-history/AiSearchToggle.vue";
import AiSearchResponse from "@/components/common/assessment-history/AiSearchResponse.vue";
import AiSearchSuggestions from "@/components/common/assessment-history/AiSearchSuggestions.vue";
import { AbortError } from "@/infrastructure/restErrors";
import { sendAssessmentAssistantMessage } from "@/components/AiAssistant/api.js";
import { HISTORY_FILTERS } from "@/components/common/assessment-history/user-history-filters.js";

export default {
  name: "UserHistoryTable",
  components: {
    AssessmentInviteDialog,
    IncompleteAssessmentReminderDialog,
    AvatarIcon,
    FilterBar,
    VitrueNewFeature,
    UserConditionsLabel,
    ExportToCSV,
    AiSearchResponse,
    AiSearch,
    AiSearchToggle,
    AiSearchSuggestions
  },
  data() {
    return {
      toggleAll: false,
      locations: Locations,
      items: [],
      users: [],
      filters: null,
      loading: true,
      tooltip: true,
      showAssessmentInviteDialog: false,
      showIncompleteAssessmentReminderDialog: false,
      preloadedAssessment: null,
      preloadedEmail: null,
      errorMessage: {
        show: false,
        text: ""
      },
      isAdvancedSearch: false,
      advancedInviteButtonText: this.$t(
        "dashboardHistoryComponents.userHistoryTable.advancedSearch.inviteButton.none"
      ),
      emailInvitePurpose: null,
      requireEmailInvitePurpose: false,
      aiModeIsActive: false,
      aiQuery: "",
      aiQueryResponsePending: false,
      aiQueryRunning: false,
      aiResponse: {},
      aiSearchAbortController: null,
      aiSettings: JSON.parse(localStorage.getItem("aiSettings")) || null
    };
  },
  created() {
    this.aiModeIsActive = !this.hideAiFeatures;
  },
  computed: {
    ...mapGetters("adminDashboard", ["dashboardState"]),
    ...mapState("adminDashboard", ["refreshTrigger"]),
    ...mapGetters({
      anonymousResults: "anonymousResults",
      adminAssessmentToView: "adminAssessmentToView",
      disableEmails: "disableEmails",
      enablePreExistingConditions: "enablePreExistingConditions",
      subscriptionExpired: "subscriptionExpired",
      hideAiFeatures: "hideAiFeatures",
      currentUserTeamAndSubscriptionBasicInformation:
        "currentUserTeamAndSubscriptionBasicInformation",
      canExportAssessmentData: "canExportAssessmentData"
    }),
    headers() {
      return [
        {
          text: "",
          value: "icon",
          hide: () => this.$vuetify.breakpoint.smAndDown,
          width: "40px"
        },
        {
          text: this.$t(
            "dashboardHistoryComponents.userHistoryTable.headers.name"
          ),
          value: "name",
          width: this.emailColumnWidth
        },
        {
          text: this.$t(
            "dashboardHistoryComponents.userHistoryTable.headers.assessmentOverview"
          ),
          value: "assessmentOverview",
          sortable: false,
          width: "265px"
        },
        {
          text: this.$t(
            "dashboardHistoryComponents.userHistoryTable.headers.tags"
          ),
          value: "tags",
          sortable: false
        },
        {
          text: this.$t(
            "dashboardHistoryComponents.userHistoryTable.headers.lastAssessment"
          ),
          value: "latestAssessmentUpdate",
          align: "center"
        },
        {
          text: this.$t(
            "dashboardHistoryComponents.userHistoryTable.headers.actions"
          ),
          value: "actions",
          sortable: false,
          align: "end"
        }
      ];
    },
    tagColors() {
      let colors = this.$vuetify.theme.currentTheme.labelColors;
      let uniqueTags = [...new Set(this.users.map(x => x.tags).flat())];
      var dict = uniqueTags.map((x, i) => [
        x,
        colors[i - colors.length * Math.floor(i / colors.length)]
      ]);
      return Object.fromEntries(dict);
    },
    userHeaders() {
      return this.headers.filter(x => !x.hide || x.hide() === false);
    },
    emailColumnWidth() {
      var width = this.$vuetify.breakpoint.lgAndDown ? 200 : 250;
      return `${width}px`;
    },
    showNewHistoryTableTooltip() {
      let showTooltip = NewFeatureService.showFeature("HistoryTableV2");
      return showTooltip && this.tooltip && !this.modalOpen;
    },
    canSendAssessmentInvitations() {
      return this.users.length > 0;
    },
    canSendIncompleteAssessmentReminders() {
      return this.usersWithIncompleteAssessments().length > 0;
    },
    canAdvancedSearchInvites() {
      return (
        this.isAdvancedSearch &&
        this.users.length > 0 &&
        (this.emailInvitePurpose !== null || !this.requireEmailInvitePurpose)
      );
    },
    assessmentsForCSV() {
      return this.users.flatMap(user => {
        return getDeskAssessmentsInCSVFormat(user);
      });
    }
  },
  watch: {
    adminAssessmentToView(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.showPreloadedUserFromAssessment();
      }
    },
    currentUserTeamAndSubscriptionBasicInformation() {
      this.getUserHistory();
    },
    aiModeIsActive(newVal) {
      // If AI search is toggled on/off this resets the data to its initial state
      // better to call the method on the FilterBar component because that's where the filters are set
      this.$refs.filterBar?.reset();

      this.stopAiSearch();
    },
    dashboardState: {
      handler(newState) {
        if (newState.basicFiltersOn) {
          this.aiModeIsActive = false;
        }

        if (newState.activeFilter) {
          // TODO: tidy up when moving history to it's own page.
          // Need to do as next tick due to reactivity issues resetting the filters after it's called
          this.$nextTick(() => {
            this.filterUsers(this.filters, newState.activeFilter);
          });
        }
      },
      deep: true
    },
    refreshTrigger() {
      this.getUserHistory();
    }
  },
  beforeDestroy() {
    this.stopAiSearch();
  },
  methods: {
    ...mapMutations(["setAdminAssessmentToView"]),
    async getUserHistory() {
      if (
        this.currentUserTeamAndSubscriptionBasicInformation &&
        this.subscriptionExpired
      ) {
        return;
      }
      try {
        this.loading = true;
        this.items = await getUserAssessmentHistory();
        this.users = filterUsers(this.items, this.filters);

        if (this.dashboardState?.activeFilter) {
          this.filterUsers(this.filters, this.dashboardState.activeFilter);
        }

        if (this.adminAssessmentToView) {
          this.showPreloadedUserFromAssessment();
        }
      } catch (err) {
        this.$logger.captureException(err);
      } finally {
        this.loading = false;
      }
    },
    getCompletedAssessmentCounts(assessments) {
      var completeCount = assessments.filter(
        x => x.state === AssessmentStateCodes.Completed
      ).length;
      var percentage =
        assessments.length > 0 ? (completeCount / assessments.length) * 100 : 0;
      return {
        completeCount,
        incompleteCount: assessments.length - completeCount,
        percentage
      };
    },
    numberCompletedAtLocation(assessments, location) {
      return assessments.filter(
        x =>
          x.location == location && x.state === AssessmentStateCodes.Completed
      ).length;
    },
    filterUsers(filters, advancedOption) {
      this.filters = filters;
      if (advancedOption) {
        switch (advancedOption) {
          case HISTORY_FILTERS.NO_HOME_ASSESSMENT:
            this.filterByNoCompletedHomeAssessment();
            break;
          case HISTORY_FILTERS.NO_OFFICE_ASSESSMENT:
            this.filterByNoCompletedOfficeAssessment();
            break;
          case HISTORY_FILTERS.HOME_AND_OFFICE_ASSESSMENT:
            this.filterByCompletedHomeAndOfficeAssessment();
            break;
          case HISTORY_FILTERS.NO_COMPLETED_DESK_ASSESSMENT:
            this.filterByNoCompletedDeskAssessmentCompleted();
            break;
          case HISTORY_FILTERS.NO_SENT_DESK_ASSESSMENT:
            this.filterByNoSentDeskAssessment();
            break;
          case HISTORY_FILTERS.HAS_HEALTH_CONDITION:
            this.filterByHasHealthCondition();
            break;
          case HISTORY_FILTERS.NEEDS_ATTENTION:
            this.filterByNeedsAttention();
            break;
          case HISTORY_FILTERS.NEEDS_ATTENTION_LAST_30_DAYS:
            this.filterByNeedsAttention30Days();
            break;
        }
        // this.items (all user data) has already been filtered in the advanced filtering function
        // Further filtering should happen on this.users (filtered data)
        this.users = filterUsers(this.users, filters);
        this.updateAdvancedInviteButtonText(advancedOption);
      } else {
        // this.items has not been filtered yet as no advanced filter is active
        this.users = filterUsers(this.items, filters);
      }
    },
    filterByNoCompletedHomeAssessment() {
      this.users = filterUsersWithoutCompletedAssessmentInEnvironment(
        this.items,
        Locations.home.value
      );
      this.emailInvitePurpose = this.$t(
        "dashboardHistoryComponents.userHistoryTable.advancedSearch.invitePurpose.home"
      );
      this.requireEmailInvitePurpose = true;
    },
    filterByNoCompletedOfficeAssessment() {
      this.users = filterUsersWithoutCompletedAssessmentInEnvironment(
        this.items,
        Locations.office.value
      );
      this.emailInvitePurpose = this.$t(
        "dashboardHistoryComponents.userHistoryTable.advancedSearch.invitePurpose.office"
      );
      this.requireEmailInvitePurpose = true;
    },
    filterByCompletedHomeAndOfficeAssessment() {
      this.users = filterUsersWithCompletedHomeAndOfficeAssessments(this.items);
      this.emailInvitePurpose = null;
      this.requireEmailInvitePurpose = true;
    },
    filterByNoCompletedDeskAssessmentCompleted() {
      this.users = filterUsersWithoutCompletedDeskAssessments(this.items);
      this.emailInvitePurpose = null;
      this.requireEmailInvitePurpose = true;
    },
    filterByNoSentDeskAssessment() {
      this.users = filterUsersWithoutAnyDeskAssessments(this.items);
      this.emailInvitePurpose = null;
      this.requireEmailInvitePurpose = false;
    },
    filterByHasHealthCondition() {
      this.users = filterUsersWithAHealthCondition(this.items);
      this.emailInvitePurpose = null;
      this.requireEmailInvitePurpose = true;
    },
    filterByNeedsAttention() {
      this.users = filterUsersWhoNeedsAttention(this.items);
      this.emailInvitePurpose = null;
      this.requireEmailInvitePurpose = true;
    },
    filterByNeedsAttention30Days() {
      this.users = filterUsersWhoNeedsAttention30Days(this.items);
      this.emailInvitePurpose = null;
      this.requireEmailInvitePurpose = true;
    },
    clearAdvancedFilter() {
      this.users = filterUsers(this.items, this.filters);
      this.emailInvitePurpose = null;
      this.requireEmailInvitePurpose = false;
      this.advancedInviteButtonText = this.$t(
        "dashboardHistoryComponents.userHistoryTable.advancedSearch.inviteButton.none"
      );
    },
    setAdvancedSearch(isAdvancedSearch) {
      this.isAdvancedSearch = isAdvancedSearch;
    },
    openAssessmentInviteDialog() {
      this.showAssessmentInviteDialog = true;
      this.$mixpanel.track(`Assessment invite dialog opened`);
    },
    openIncompleteAssessmentReminderDialog() {
      this.showIncompleteAssessmentReminderDialog = true;
      this.$mixpanel.track(`Assessment reminder dialog opened`);
    },
    usersWithIncompleteAssessments() {
      return this.users.filter(
        user =>
          user.assessments.filter(
            assessment => assessment.state !== AssessmentStateCodes.Completed
          ).length > 0
      );
    },
    customSort(items, sortBy, isDesc) {
      if (sortBy.includes("name")) {
        items.sort((a, b) => this.sortNameEmail(a, b, isDesc));
      } else if (sortBy.includes("latestAssessmentUpdate")) {
        items.sort((a, b) => this.sortLastestAssessment(a, b, isDesc));
      }
      return items;
    },
    sortNameEmail(user1, user2, isDesc) {
      var user1Name = !!user1.name ? user1.name : user1.email;
      var user2Name = !!user2.name ? user2.name : user2.email;
      return isDesc.includes(false)
        ? user1Name.localeCompare(user2Name)
        : user2Name.localeCompare(user1Name);
    },
    sortLastestAssessment(user1, user2, isDesc) {
      if (user1.assessments.length === 0) return 1;
      if (user2.assessments.length === 0) return -1;
      var user1Date = this.getLatestAssessmentDate(user1);
      var user2Date = this.getLatestAssessmentDate(user2);
      return isDesc.includes(false)
        ? user1Date - user2Date
        : user2Date - user1Date;
    },
    getHomeOfficeRisks(user) {
      return getHomeOfficeRisks(user);
    },
    getAllRisks(item) {
      return Object.values(getHomeOfficeRisks(item)).flat();
    },
    getRiskText(item) {
      let allRisks = this.getAllRisks(item);
      return this.$tc(
        "dashboardHistoryComponents.userHistoryTable.risks",
        allRisks.length
      );
    },
    showLocationRisks(user, location) {
      var risks = getHomeOfficeRisks(user);

      return location in risks && risks[location].length > 0;
    },
    getLatestAssessmentDate(user) {
      if (!this.filters) {
        return new Date(user.latestAssessmentUpdate);
      }
      return getLatestAssessmentDate(user);
    },
    getUniqueSortedTags(tags) {
      var uniqueTags = [...new Set(tags)];
      uniqueTags.sort();
      return uniqueTags;
    },
    showPreloadedUserFromAssessment() {
      var userAssessment = getPreloadedUserAssessment(this.items);

      if (!userAssessment) {
        this.showErrorMessage(this.$t(preloadErrorMessagePath));
        this.resetPreloadedAssessment();
        return;
      }

      this.preloadedAssessment = userAssessment.assessment;
      this.preloadedEmail = userAssessment.user.email;

      this.resetPreloadedAssessment();
    },
    async clearPreloadedAssessmentAndEmail() {
      this.preloadedEmail = "";
      this.preloadedAssessment = null;
    },
    resetPreloadedAssessment() {
      this.setAdminAssessmentToView(null);
      var cleanURL = window.location.origin + window.location.hash;
      window.history.replaceState(null, "", cleanURL);
    },
    showErrorMessage(text) {
      this.errorMessage.text = text;
      this.errorMessage.show = true;
    },
    updateAdvancedInviteButtonText(advancedOption) {
      let textKey =
        "dashboardHistoryComponents.userHistoryTable.advancedSearch.inviteButton.otherFilter";
      const userCount = { 0: this.users.length };

      switch (advancedOption) {
        case HISTORY_FILTERS.NO_HOME_ASSESSMENT:
          textKey =
            "dashboardHistoryComponents.userHistoryTable.advancedSearch.inviteButton.home";
          break;
        case HISTORY_FILTERS.NO_OFFICE_ASSESSMENT:
          textKey =
            "dashboardHistoryComponents.userHistoryTable.advancedSearch.inviteButton.office";
          break;
        case HISTORY_FILTERS.NO_SENT_DESK_ASSESSMENT:
          textKey =
            "dashboardHistoryComponents.userHistoryTable.inviteXAssessments";
          break;
        case HISTORY_FILTERS.HOME_AND_OFFICE_ASSESSMENT:
        case HISTORY_FILTERS.HAS_HEALTH_CONDITION:
        case HISTORY_FILTERS.NO_COMPLETED_DESK_ASSESSMENT:
        case HISTORY_FILTERS.NEEDS_ATTENTION:
        case HISTORY_FILTERS.NEEDS_ATTENTION_LAST_30_DAYS:
          break;
        default:
          textKey =
            "dashboardHistoryComponents.userHistoryTable.advancedSearch.inviteButton.default";
          break;
      }

      this.advancedInviteButtonText = this.$t(textKey, userCount);
    },
    onSearchSuggestion(query) {
      this.$refs?.aiSearch.forceSearch(query);
    },
    async applyAiSearch(query) {
      if (this.hideAiFeatures) return;

      if (this.aiSearchAbortController) {
        this.aiSearchAbortController.abort();
      }

      this.aiSearchAbortController = new AbortController();
      this.aiQueryResponsePending = true;
      this.aiQueryRunning = true;
      this.aiQuery = query;
      this.aiResponse = {};

      try {
        const deltaUpdate = async delta => {
          switch (delta.type) {
            case "TriageDelta": {
              this.aiResponse = {
                type: "assistantSelected",
                text: delta.text
              };
              this.aiQueryResponsePending = false;
              break;
            }
            case "UserAssessmentsAssistantDelta": {
              this.aiResponse = {
                type: "assistantSelected",
                text: delta.text
              };
              break;
            }
            case "UserAssessmentsAssistantResponse": {
              const results = delta.userAssessmentHistory;
              const userAssessments = results?.userAssessments;

              this.users = userAssessments ?? [];
              this.aiResponse = {
                type: "success",
                text: results.explanation,
                interactionId: results.interactionId,
                sessionId: results.sessionId,
                count: userAssessments
                  ? this.$t("aiSearch.response.usersShown", {
                      usersFound: userAssessments.length,
                      totalUsers: this.items.length
                    })
                  : ""
              };
              break;
            }
          }
        };

        await sendAssessmentAssistantMessage(
          query,
          this.aiSettings,
          deltaUpdate,
          this.aiSearchAbortController.signal
        );
      } catch (error) {
        if (error instanceof AbortError) return;

        this.aiResponse = {
          type: "error",
          text: this.$t("aiSearch.response.error")
        };
      } finally {
        this.aiQueryResponsePending = false;
        this.aiQueryRunning = false;
        this.aiSearchAbortController = null;
      }
    },
    stopAiSearch() {
      if (this.aiSearchAbortController) {
        this.aiSearchAbortController.abort();
        this.aiSearchAbortController = null;
        this.aiQueryResponsePending = false;
        this.aiQueryRunning = false;
      }
    },
    handleAiSearchReset() {
      this.$refs.filterBar?.reset();
      this.aiResponse = {};
    },
    handleRowClick(event) {
      this.$emit("openUserProfile", event);
    },
    handleAiSearchSettingsChanged(newSettings) {
      this.aiSettings = newSettings.useDefaults
        ? null
        : {
            sessionId: newSettings.maintainSession
              ? this.aiResponse.sessionId
              : null,
            selectedAssistant: newSettings.selectedAssistant
          };

      localStorage.setItem("aiSettings", JSON.stringify(this.aiSettings));
    }
  }
};
</script>

<style scoped>
.singleLine {
  white-space: nowrap;
  overflow-x: auto;
  flex-wrap: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.singleLine::-webkit-scrollbar {
  display: none;
}

::v-deep tr:hover {
  cursor: pointer;
}

.incompleteCount {
  font-size: 10px !important;
}
</style>
