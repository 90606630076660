<template>
  <v-navigation-drawer
    :value="isOpen"
    @input="handleInput"
    @transitionend="onTransitionEnd"
    fixed
    right
    :width="componentWidth"
    :hide-overlay="!hasOverlay"
    stateless
    style="z-index: 20"
  >
    <div class="d-flex align-end px-1">
      <button
        v-if="showBackButton"
        @click="goBack"
        class="vfl-button-primary-ghost is-icon-only back-button mr-2"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </button>
      <h1 v-if="title" class="text-body-1 text-body-bold pl-3">{{ title }}</h1>
      <button
        @click="close"
        class="vfl-button-primary-ghost is-icon-only ml-auto"
      >
        <v-icon>mdi-close</v-icon>
      </button>
    </div>

    <div
      class="pa-4"
      :class="{ 'pt-0': !title }"
      style="height: calc(-24px + 100%)"
    >
      <slot></slot>
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "VflSidepanel",
  props: {
    isOpen: Boolean,
    width: {
      type: String,
      default: "420px"
    },
    hasOverlay: {
      type: Boolean,
      default: false
    },
    showBackButton: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    }
  },
  computed: {
    componentWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return "100%";
      }

      return this.width;
    }
  },
  methods: {
    handleInput(value) {
      if (this.isOpen !== value) {
        this.$emit("update:is-open", value);
      }
    },
    close() {
      this.$emit("update:is-open", false);
    },
    onTransitionEnd() {
      if (!this.isOpen) {
        this.$emit("onCloseComplete");
      }
    },
    goBack() {
      this.$emit("onBackClick");
    }
  }
};
</script>
