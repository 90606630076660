import RestApiClient from "@/infrastructure/restApiClient.js";

export async function getTeamRisksForcurrentUser() {
  const path = `/api/team/risks`;
  return await RestApiClient.get(path);
}

export async function getTeamPainDistribution() {
  const path = `/api/users/pain/distribution`;
  return await RestApiClient.get(path);
}

export async function getPeopleWithUniqueRequirements() {
  const path = `/api/users/unique-requirements`;
  return await RestApiClient.get(path);
}
