import {
  getAssessmentConversations,
  sendAssistantMessage,
  sendStrategyAssistantMessage
} from "@/components/AiAssistant/api.js";
import Vue from "vue";
import {
  StoreAction,
  StoreDispatch,
  Role,
  ErrorMessage,
  AssistantTypes
} from "@/components/AiAssistant/constants.js";
import { AbortError } from "@/infrastructure/restErrors";
import mixpanel from "mixpanel-browser";
import { aiFeaturesDisabledMessage } from "@/components/common/ai/utils.js";
import strategySeedQuestions from "@/assets/json/ai-seed-questions-strategy-overview.json";
import mainStore from "@/store";

export const aiAssistantStore = {
  namespaced: true,
  state: {
    abortController: null,
    currentAssessmentInfo: null,
    conversations: [],
    currentConversationId: null,
    error: null,
    isAiWindowOpen: false,
    isAssistantTyping: false,
    conversationSuggestions: strategySeedQuestions,
    awaitingAssistantResponse: false,
    isLoadingConversations: false,
    isContextLoading: false,
    isProcessingRequest: false,
    assistantType: AssistantTypes.STRATEGY
  },
  mutations: {
    SET_AI_WINDOW_OPEN(state, isOpen) {
      state.isAiWindowOpen = isOpen;
    },
    SET_ASSISTANT_TYPE(state, type) {
      state.assistantType = type;
    },
    SET_LOADING_CONVERSATIONS(state, isLoadingConversations) {
      state.isLoadingConversations = isLoadingConversations;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
    SET_CURRENT_ASSESSMENT_INFO(state, assessmentInfo) {
      state.currentAssessmentInfo = {
        ...state.currentAssessmentInfo,
        ...assessmentInfo
      };
    },
    SET_CONVERSATIONS(state, conversations) {
      state.conversations = conversations;
    },
    SET_ASSISTANT_TYPING(state, isTyping) {
      state.isAssistantTyping = isTyping;
    },
    SET_AWAITING_ASSISTANT_RESPONSE(state, waiting) {
      state.awaitingAssistantResponse = waiting;
    },
    SET_ABORT_CONTROLLER(state, controller) {
      state.abortController = controller;
    },
    SET_CURRENT_CONVERSATION(state, id) {
      state.currentConversationId = id;
    },
    ADD_CONVERSATION(state, conversation) {
      state.conversations = [...state.conversations, conversation];
    },
    UPDATE_CONVERSATION(state, { conversationId, updates }) {
      const index = state.conversations.findIndex(c => c.id === conversationId);
      if (index !== -1) {
        const updatedConversations = [...state.conversations];
        updatedConversations[index] = {
          ...state.conversations[index],
          ...updates
        };
        state.conversations = updatedConversations;
      }
    },
    ADD_MESSAGE(state, { conversationId, message }) {
      const conversationIndex = state.conversations.findIndex(
        c => c.id === conversationId
      );
      if (conversationIndex !== -1) {
        const updatedConversations = [...state.conversations];
        updatedConversations[conversationIndex] = {
          ...updatedConversations[conversationIndex],
          messages: [
            ...updatedConversations[conversationIndex].messages,
            message
          ]
        };
        state.conversations = updatedConversations;
      }
    },
    UPDATE_MESSAGE(state, { conversationId, messageId, updates }) {
      const conversation = state.conversations.find(
        c => c.id === conversationId
      );
      if (conversation) {
        const messageIndex = conversation.messages.findIndex(
          m => m.id === messageId
        );

        if (messageIndex !== -1) {
          const updatedMessage = {
            ...conversation.messages[messageIndex],
            ...updates
          };
          Vue.set(conversation.messages, messageIndex, updatedMessage);
        }
      }
    },
    ADD_CONVERSATION_SUGGESTIONS(state, suggestions) {
      state.conversationSuggestions = suggestions;
    },
    REMOVE_CONVERSATION_SUGGESTIONS(state) {
      state.conversationSuggestions = null;
    },
    CLEAR_ALL_CONVERSATION_SUGGESTIONS(state) {
      state.conversationSuggestions = null;
    },
    REMOVE_MESSAGE(state, { conversationId, messageId }) {
      const conversation = state.conversations.find(
        c => c.id === conversationId
      );
      if (conversation) {
        conversation.messages = conversation.messages.filter(
          m => m.id !== messageId
        );
      }
    },
    SET_CONTEXT_LOADING(state, isLoading) {
      state.isContextLoading = isLoading;
    },
    SET_PROCESSING_REQUEST(state, isProcessing) {
      state.isProcessingRequest = isProcessing;
    }
  },
  actions: {
    async reset({ commit, dispatch }) {
      commit(StoreAction.SET_CURRENT_ASSESSMENT_INFO, null);
      commit(StoreAction.SET_ASSISTANT_TYPE, AssistantTypes.STRATEGY);
      commit(StoreAction.CLEAR_ALL_CONVERSATION_SUGGESTIONS, null);
      commit(StoreAction.ADD_CONVERSATION_SUGGESTIONS, strategySeedQuestions);
      await dispatch(StoreDispatch.RESET_REQUEST_STATE);
      commit(StoreAction.SET_CONVERSATIONS, []);
    },
    openAiWindow({ commit }) {
      commit(StoreAction.SET_AI_WINDOW_OPEN, true);
    },
    closeAiWindow({ commit }) {
      commit(StoreAction.SET_AI_WINDOW_OPEN, false);
    },
    async fetchAssessmentConversations({ commit, state }) {
      commit(StoreAction.SET_LOADING_CONVERSATIONS, true);

      try {
        const response = await getAssessmentConversations(
          state.currentAssessmentInfo.id
        );
        commit(StoreAction.SET_CONVERSATIONS, response);
        if (response.length > 0) {
          const latestConversation = response[response.length - 1];
          commit(StoreAction.SET_CURRENT_CONVERSATION, latestConversation.id);
        }
      } catch (error) {
        commit(StoreAction.SET_ERROR, error.message);
      } finally {
        commit(StoreAction.SET_LOADING_CONVERSATIONS, false);
      }
    },
    async createNewConversation({ commit, state }) {
      const existingEmptyConversation = state.conversations.find(
        c =>
          c.messages.length === 0 ||
          (c.messages.length === 1 && c.messages[0].isTemp)
      );

      const newConversationId = `temp-${Date.now()}`;
      if (existingEmptyConversation) {
        commit(StoreAction.UPDATE_CONVERSATION, {
          conversationId: existingEmptyConversation.id,
          updates: {
            id: newConversationId,
            messages: [],
            isTemp: true
          }
        });
        commit(StoreAction.SET_CURRENT_CONVERSATION, newConversationId);
      } else {
        commit(StoreAction.ADD_CONVERSATION, {
          id: newConversationId,
          messages: [],
          isTemp: true
        });
        commit(StoreAction.SET_CURRENT_CONVERSATION, newConversationId);
      }
    },
    async createNewConversationAndSendMessage(
      { commit, state, dispatch },
      { message }
    ) {
      if (state.isProcessingRequest) {
        await dispatch(StoreDispatch.ABORT_CURRENT_REQUEST);
      }
      commit(StoreAction.SET_PROCESSING_REQUEST, true);

      try {
        await dispatch(StoreDispatch.CREATE_NEW_CONVERSATION);
        await dispatch(StoreDispatch.SEND_MESSAGE, { message: message });
      } finally {
        commit(StoreAction.SET_PROCESSING_REQUEST, false);
      }
    },
    async sendMessage({ commit, state, dispatch, rootGetters }, { message }) {
      if (rootGetters.disableAiFeatures) {
        await dispatch(StoreDispatch.SHOW_FEATURE_DISABLED_MESSAGE, {
          message
        });
        return;
      }

      await dispatch(StoreDispatch.PREPARE_FOR_NEW_MESSAGE);

      const { currentConversation, isNewConversation } = await dispatch(
        StoreDispatch.SETUP_CONVERSATION
      );
      const { tempMessageId, tempAssistantMessageId } = await dispatch(
        StoreDispatch.CREATE_TEMP_MESSAGES,
        { currentConversation, message }
      );

      try {
        await dispatch(StoreDispatch.PROCESS_ASSISTANT_RESPONSE, {
          currentConversation,
          isNewConversation,
          tempMessageId,
          tempAssistantMessageId,
          message
        });
      } catch (error) {
        await dispatch(StoreDispatch.HANDLE_ERROR_RESPONSE, {
          error,
          isNewConversation,
          conversationId: currentConversation.id,
          tempMessageId,
          tempAssistantMessageId,
          message
        });
      } finally {
        await dispatch(StoreDispatch.FINALISE_REQUEST);
      }
    },
    async prepareForNewMessage({ commit, state, dispatch }) {
      if (state.isProcessingRequest) {
        await dispatch(StoreDispatch.ABORT_CURRENT_REQUEST);
      }
      commit(StoreAction.SET_PROCESSING_REQUEST, true);
      commit(StoreAction.SET_AWAITING_ASSISTANT_RESPONSE, true);
      commit(StoreAction.SET_ERROR, null);
    },
    async setupConversation({ state, dispatch }) {
      let currentConversation = state.conversations.find(
        c => c.id === state.currentConversationId
      );
      const isNewConversation =
        !currentConversation || currentConversation.isTemp;

      if (!currentConversation) {
        await dispatch(StoreDispatch.CREATE_NEW_CONVERSATION);
        currentConversation = state.conversations.find(
          c => c.id === state.currentConversationId
        );
      }

      return { currentConversation, isNewConversation };
    },
    async createTempMessages({ commit }, { currentConversation, message }) {
      const tempMessageId = `temp-${Date.now()}`;
      const tempAssistantMessageId = `temp-assistant-${Date.now()}`;

      commit(StoreAction.ADD_MESSAGE, {
        conversationId: currentConversation.id,
        message: {
          id: tempMessageId,
          content: message,
          role: Role.USER,
          isTemp: true
        }
      });

      commit(StoreAction.ADD_MESSAGE, {
        conversationId: currentConversation.id,
        message: {
          id: tempAssistantMessageId,
          content: "",
          role: Role.ASSISTANT,
          isTemp: true
        }
      });

      return { tempMessageId, tempAssistantMessageId };
    },
    async processAssistantResponse(
      { commit, state, dispatch },
      {
        currentConversation,
        isNewConversation,
        tempMessageId,
        tempAssistantMessageId,
        message
      }
    ) {
      const abortController = new AbortController();
      commit(StoreAction.SET_ABORT_CONTROLLER, abortController);

      const updateStream = await dispatch(StoreDispatch.CREATE_STREAM_UPDATER, {
        conversationId: currentConversation.id,
        isNewConversation,
        tempMessageId,
        tempAssistantMessageId
      });

      switch (state.assistantType) {
        case AssistantTypes.REPORT:
          await sendAssistantMessage(
            state.currentAssessmentInfo.id,
            isNewConversation ? null : currentConversation.id,
            message,
            JSON.stringify({ report: state?.currentAssessmentInfo?.summary }),
            abortController.signal,
            updateStream
          );
          break;
        case AssistantTypes.STRATEGY:
          await sendStrategyAssistantMessage(
            isNewConversation ? null : currentConversation.id,
            message,
            updateStream,
            abortController.signal
          );
          break;
      }
    },
    async createStreamUpdater(
      { commit, dispatch },
      {
        conversationId,
        isNewConversation,
        tempMessageId,
        tempAssistantMessageId
      }
    ) {
      let streamStarted = false;
      let assistantMessage = "";

      return async function (delta) {
        if (!streamStarted) {
          streamStarted = true;
          commit(StoreAction.SET_AWAITING_ASSISTANT_RESPONSE, false);
          commit(StoreAction.SET_ASSISTANT_TYPING, true);
        }

        switch (delta.type) {
          case "TextDelta":
            assistantMessage += delta.message;
            commit(StoreAction.UPDATE_MESSAGE, {
              conversationId: conversationId,
              messageId: tempAssistantMessageId,
              updates: { content: assistantMessage }
            });
            break;
          case "Metadata":
            await dispatch(StoreDispatch.UPDATE_MESSAGES, {
              conversationId: conversationId,
              tempMessageId,
              tempAssistantMessageId,
              result: delta
            });

            if (isNewConversation) {
              await dispatch(StoreDispatch.UPDATE_CONVERSATION, {
                conversationId: conversationId,
                newSessionId: delta.sessionId
              });
            }
            break;
        }
      };
    },
    updateConversation({ commit }, { conversationId, newSessionId }) {
      commit(StoreAction.UPDATE_CONVERSATION, {
        conversationId: conversationId,
        updates: { id: newSessionId, isTemp: false }
      });
      commit(StoreAction.SET_CURRENT_CONVERSATION, newSessionId);
    },
    updateMessages(
      { commit },
      { conversationId, tempMessageId, tempAssistantMessageId, result }
    ) {
      commit(StoreAction.UPDATE_MESSAGE, {
        conversationId: conversationId,
        messageId: tempMessageId,
        updates: { id: result.id, createdAt: result.createdAt, isTemp: false }
      });

      commit(StoreAction.UPDATE_MESSAGE, {
        conversationId: conversationId,
        messageId: tempAssistantMessageId,
        updates: {
          id: `${result.id}`,
          createdAt: result.createdAt,
          isTemp: false
        }
      });
    },
    async finaliseRequest({ dispatch, commit }) {
      await dispatch(StoreDispatch.RESET_REQUEST_STATE);
      commit(StoreAction.SET_PROCESSING_REQUEST, false);
    },

    handleErrorResponse(
      { commit, dispatch },
      {
        error,
        isNewConversation,
        conversationId,
        tempMessageId,
        tempAssistantMessageId,
        message
      }
    ) {
      const userAborted = error instanceof AbortError;
      if (userAborted) {
        dispatch(StoreDispatch.HANDLE_ABORTED_REQUEST, {
          conversationId,
          tempMessageId,
          tempAssistantMessageId
        });
      } else {
        dispatch(StoreDispatch.HANDLE_NON_ABORT_ERROR, {
          error,
          conversationId,
          tempMessageId,
          tempAssistantMessageId,
          message
        });

        if (isNewConversation) {
          commit(StoreAction.SET_CURRENT_CONVERSATION, null);
        }
      }
    },
    handleAbortedRequest(
      { commit, state },
      { conversationId, tempMessageId, tempAssistantMessageId }
    ) {
      commit(StoreAction.SET_ERROR, ErrorMessage.REQUEST_CANCELLED);

      // Set isTemp to false for user message
      commit(StoreAction.UPDATE_MESSAGE, {
        conversationId,
        messageId: tempMessageId,
        updates: { isTemp: false }
      });

      const assistantMessage = state.conversations
        .find(c => c.id === conversationId)
        ?.messages.find(m => m.id === tempAssistantMessageId);

      if (assistantMessage && assistantMessage.content) {
        commit(StoreAction.UPDATE_MESSAGE, {
          conversationId,
          messageId: tempAssistantMessageId,
          updates: { isTemp: false }
        });
      } else {
        commit(StoreAction.REMOVE_MESSAGE, {
          conversationId,
          messageId: tempAssistantMessageId
        });
      }
    },
    handleNonAbortError(
      { commit },
      { error, conversationId, tempMessageId, tempAssistantMessageId, message }
    ) {
      commit(StoreAction.SET_ERROR, error.message);

      // Update user message
      commit(StoreAction.UPDATE_MESSAGE, {
        conversationId,
        messageId: tempMessageId,
        updates: {
          id: tempMessageId,
          content: message,
          role: Role.USER,
          createdAt: new Date().toISOString(),
          error: true,
          isTemp: false
        }
      });

      // Remove assistant message
      commit(StoreAction.REMOVE_MESSAGE, {
        conversationId,
        messageId: tempAssistantMessageId
      });
    },
    resetRequestState({ commit }) {
      commit(StoreAction.SET_LOADING_CONVERSATIONS, false);
      commit(StoreAction.SET_ASSISTANT_TYPING, false);
      commit(StoreAction.SET_AWAITING_ASSISTANT_RESPONSE, false);
      commit(StoreAction.SET_ABORT_CONTROLLER, null);
    },
    async abortCurrentRequest({ state, commit }) {
      if (state.abortController) {
        state.abortController.abort();
        commit(StoreAction.SET_ABORT_CONTROLLER, null);
      }
    },
    addConversationSuggestions({ commit }, suggestions) {
      commit(StoreAction.ADD_CONVERSATION_SUGGESTIONS, suggestions);
    },
    removeConversationSuggestions({ commit }) {
      commit(StoreAction.REMOVE_CONVERSATION_SUGGESTIONS);
    },
    clearAllConversationSuggestions({ commit }) {
      commit(StoreAction.REMOVE_CONVERSATION_SUGGESTIONS);
    },
    updateAssessmentInfo({ commit }, assessmentInfo) {
      commit("SET_CURRENT_ASSESSMENT_INFO", assessmentInfo);
    },
    setContextLoading({ commit }, isLoading) {
      commit(StoreAction.SET_CONTEXT_LOADING, isLoading);
    },
    async showFeatureDisabledMessage({ commit, state }, { message }) {
      const tempMessageId = `temp-${Date.now()}`;
      const assistantMessageId = `assistant-${Date.now()}`;
      const systemMessage = aiFeaturesDisabledMessage(state, true);

      mixpanel.track("AI assistant interaction with feature disabled", {
        message
      });

      commit(StoreAction.ADD_MESSAGE, {
        conversationId: state.currentConversationId,
        message: {
          id: tempMessageId,
          content: message,
          role: Role.USER,
          createdAt: new Date().toISOString()
        }
      });

      commit(StoreAction.ADD_MESSAGE, {
        conversationId: state.currentConversationId,
        message: {
          id: assistantMessageId,
          content: systemMessage,
          role: Role.SYSTEM,
          createdAt: new Date().toISOString()
        }
      });
    },
    setAssistantType({ commit }, type) {
      commit(StoreAction.SET_ASSISTANT_TYPE, type);
    }
  },
  getters: {
    error: state => state.error,
    isAiWindowOpen: state => state.isAiWindowOpen,
    isAssistantTyping: state => state.isAssistantTyping,
    isLoadingConversations: state => state.isLoadingConversations,
    conversations: state => state.conversations,
    currentConversation: state => {
      return state.conversations.find(
        c => c.id === state.currentConversationId
      );
    },
    conversationSuggestions: state => state.conversationSuggestions,
    awaitingAssistantResponse: state => state.awaitingAssistantResponse,
    currentAssessmentInfo: state => state.currentAssessmentInfo,
    isContextLoading: state => state.isContextLoading,
    assistantType: state => state.assistantType,
    bumpUpAssistant: state =>
      state.assistantType === AssistantTypes.STRATEGY &&
      mainStore.getters.showOnboardingChecklist
  }
};
