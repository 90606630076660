<template>
  <vfl-icon-button
    @click="download"
    class="html2canvas-ignore"
    :loading="downloadingReport"
    :disabled="downloadingReport"
    isLight
    size="small"
    :screenReader="$t(`mskOverview.downloadOverview`)"
  >
    <v-icon small color="vflPrimary">mdi-download</v-icon>
  </vfl-icon-button>
</template>

<script>
import html2canvas from "html2canvas";
import VflIconButton from "@/components/vitrue-foundation-library/VflIconButton.vue";
import { sendEvent } from "@/services/analytics/analytics.js";

export default {
  name: "PainOverviewDownloadButton",
  components: {
    VflIconButton
  },
  data() {
    return { downloadingReport: false };
  },
  methods: {
    download() {
      if (this.downloadingReport) return;
      this.downloadingReport = true;

      sendEvent("MSK Overview downloaded");

      this.$nextTick(() => {
        setTimeout(async () => {
          try {
            const element = document.getElementById("pain-overview-container");
            const canvas = await html2canvas(element, {
              scale: 2,
              ignoreElements: element => {
                return element.classList.contains("html2canvas-ignore");
              }
            });
            const imageData = canvas.toDataURL("image/png");
            this.triggerDownload(imageData, "pain_overview.png");
          } catch (error) {
            this.$logger.captureException(error);
          } finally {
            this.downloadingReport = false;
          }
        }, 0);
      });
    },
    triggerDownload(imageData, filename) {
      const link = document.createElement("a");
      link.href = imageData;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
</script>
