<template>
  <button @click="handleClick" :class="classList">
    <slot name="startDecorator"></slot>
    <slot></slot>
    <slot name="endDecorator"></slot>
  </button>
</template>
<script>
export default {
  name: "VflButton",
  props: {
    variant: {
      type: String,
      validator: value => ["primary"].includes(value),
      default: "primary"
    },
    isLight: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      validator: value => ["small", "medium", "large"].includes(value),
      default: "medium"
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classList() {
      return [
        `is-${this.variant}`,
        `is-${this.size}`,
        { "is-light": this.isLight },
        { "is-loading": this.loading }
      ];
    }
  },
  methods: {
    handleClick(event) {
      if (!this.disabled) {
        this.$emit("click", event);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
button {
  background-color: white;
  border: 1px solid transparent;
  border-radius: 24px;
  color: var(--v-vflTextDark-base);
  font: inherit;
  font-weight: 700;
  padding: 0.75rem 1rem;
  transition: box-shadow 0.2s cubic-bezier(0.2, 0, 0, 1),
    transform 0.1s cubic-bezier(0.2, 0, 0, 1);

  &.is-primary:not(.is-light) {
    background-color: var(--v-vflPrimary-base);
    color: white;
  }

  &:active {
    transform: scale(0.96);
  }

  &.is-light {
    &.is-primary {
      background-color: var(--v-vflPrimaryLight-base);
      color: var(--v-vflPrimary-base);
    }
  }

  &.is-small {
    font-size: 0.875rem;
    line-height: 1.285715;
    padding: 0.125rem 0.75rem;
  }

  &:hover {
    filter: brightness(102%);
  }

  &.is-loading {
    color: transparent; // hides text in the button
    > * {
      visibility: hidden; // hides child elements within the button
    }
    pointer-events: none;
    position: relative;
  }

  &.is-loading::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    margin-top: -8px;
    margin-left: -8px;
    border: 2px solid var(--v-vflPrimary-base);
    border-top-color: transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
