<template>
  <div class="container d-flex align-center">
    <div class="icon">
      <img
        src="/images/icons/attention_empty_state_icon.png"
        alt="Empty state icon"
        width="55"
        height="55"
      />
    </div>
    <div class="text-content">
      <h3 class="text-subtitle-1">
        {{ $t("needsAttentionTable.emptyState.title") }}
      </h3>
      <p class="text-body-2 vflGreyLight--text mb-0">
        {{ $t("needsAttentionTable.emptyState.text") }}
      </p>
      <button
        class="vfl-button-link pt-2 see-all-people-button"
        @click="seeAllPeopleWithRisks"
      >
        {{ $t("needsAttentionTable.emptyState.button") }}
      </button>
    </div>
  </div>
</template>

<script>
import { track } from "@/customApi.js";
import { HISTORY_FILTERS } from "@/components/common/assessment-history/user-history-filters.js";

export default {
  name: "WhoNeedsAttentionEmptyState",
  methods: {
    seeAllPeopleWithRisks() {
      track("Who Needs Help", {
        info: "seeAllPeopleWithRisks clicked"
      });
      this.updateDashboard();
    },
    updateDashboard() {
      const scrollToId = "user-history-table";

      this.$store.dispatch("adminDashboard/triggerDashboardUpdate", {
        filter: HISTORY_FILTERS.NEEDS_ATTENTION,
        scrollTargetId: scrollToId
      });
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  align-items: flex-start;
  margin: 0;
  padding: 24px;
}

.text-content {
  text-align: start;
}

.icon {
  margin-right: 20px;
}

.icon img {
  width: 60px;
  height: 60px;
}

.see-all-people-button {
  text-align: left;
  white-space: initial;
}
</style>
