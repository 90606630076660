<template>
  <div>
    <feature-introduction-card
      v-if="showAdminFeedbackPopup"
      featureKey="AdminDashboardFeedback"
      imageLink="/images/illustrations/feedback-popup.svg"
      :title="$t('featureIntroductions.adminDashboardFeedback.title')"
      :description="
        $t('featureIntroductions.adminDashboardFeedback.description')
      "
      :actionButtonText="
        $t('featureIntroductions.adminDashboardFeedback.button')
      "
      :customCompleteFunction="openFeedbackSurvey"
    />
    <total-seats-warning-bar
      id="dashboardSeatWarning"
      class="pa-6"
      showCloseButton
    />

    <div class="mx-10 mb-4" v-if="showAccountBanner">
      <account-banner v-model="showAccountBanner" />
    </div>
    <v-dialog v-if="webcamQuickAssess" v-model="webcamQuickAssess">
      <quick-assess type="webcamAnalysis" v-model="webcamQuickAssess" />
    </v-dialog>
    <v-dialog
      v-if="simsQuickAssess"
      v-model="simsQuickAssess"
      :fullscreen="$vuetify.breakpoint.xs"
    >
      <quick-assess type="deskSetup" v-model="simsQuickAssess" />
    </v-dialog>
    <v-dialog v-if="showWelcomeDialog" v-model="welcomeDialog">
      <AdminTour v-if="welcomeDialog" v-model="welcomeDialog" />
    </v-dialog>
    <v-dialog
      v-if="inviteDialog"
      v-model="inviteDialog"
      :width="inviteDialogWidth"
      persistent
      max-width="1020"
    >
      <invite-user-to-assessment-dialog
        v-if="inviteDialog"
        v-model="inviteDialog"
        :assessmentType="inviteAssessmentType"
        :preloadedUser="preloadedInviteUser"
        @preloadedUser="preloadedInviteUser = null"
      />
    </v-dialog>

    <dashboard-assessment-cards
      v-if="!deactivatedUser"
      :loading="loading"
      @onAssessmentCardClicked="onAssessmentCardClicked"
    />
    <dashboard-deactivated-user-banner v-else />

    <div class="dashboard-grid">
      <pain-overview class="pain-overview-card" />
      <who-needs-attention
        v-if="!hideWhoNeedsAttentionTable"
        class="who-needs-attention-card"
      />
      <analytics-table
        ref="analyticsTable"
        class="analytics-card ma-0"
        :is-full-width="isAnalyticsTableFullWidth"
      />
    </div>

    <div class="mt-10" id="user-history-table">
      <user-history-table
        ref="userHistory"
        @sendInvite="showInviteWithPreloadedUser($event)"
        @openUserProfile="openUserProfile($event)"
      />
    </div>

    <AiAssistantContainer
      v-if="showDashboardAssistant"
      :type="$options.assistantTypes.STRATEGY"
    />

    <vfl-sidepanel
      :is-open="sidePanelActive"
      @update:is-open="handleSidePanelChange"
      @onCloseComplete="handleSidePanelCloseComplete"
      @onBackClick="handleSidePanelBackClick"
      :show-back-button="showSidePanelBackButton"
      :title="sidePanelTitle"
    >
      <user-list v-if="sidePanelViewMode === 'list'" />
      <user-profile :user-id="selectedPersonId" layout="sidepanel" />
    </vfl-sidepanel>
  </div>
</template>

<script>
import AccountBanner from "@/components/common/AccountBanner.vue";
import DashboardAssessmentCards from "@/components/dashboard/assessment-cards/DashboardAssessmentCards.vue";
import DashboardDeactivatedUserBanner from "@/components/dashboard/DashboardDeactivatedUserBanner.vue";
const InviteUserToAssessmentDialog = () =>
  import("./InviteDialog/InviteUserToAssessmentDialog.vue");
import UserHistoryTable from "@/components/common/assessment-history/UserHistoryTable.vue";
import AnalyticsTable from "./analytics/AnalyticsTable.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { getTeamMember } from "@/customApi";
import { Auth } from "aws-amplify";
import AdminTour from "@/components/account/onboarding/AdminTour.vue";
import { updateCheckListFromFeatures } from "@/services/onboarding/adminOnboarding";
import TeamService from "@/services/teamService.js";
import FeatureService from "@/services/new-feature-service.js";
import QuickAssess from "@/components/dashboard/QuickAssess.vue";
import TotalSeatsWarningBar from "@/components/dashboard/TotalSeatsWarningBar.vue";
import * as Constants from "@/constants/constants.js";
import NewFeatureService from "@/services/new-feature-service.js";
import FeatureIntroductionCard from "@/components/common/FeatureIntroductionCard.vue";
import AiAssistantContainer from "@/components/AiAssistant/AiAssistantContainer.vue";
import { AssistantTypes } from "@/components/AiAssistant/constants.js";
import WhoNeedsAttention from "@/components/dashboard/tables/WhoNeedsAttention.vue";
import UserProfile from "@/components/user-profile/UserProfile.vue";
import VflSidepanel from "@/components/vitrue-foundation-library/VflSidepanel.vue";
import { sendEvent } from "@/services/analytics/analytics.js";
import PainOverview from "@/components/dashboard/cards/pain-overview/PainOverview.vue";
import UserList from "@/components/user-list/UserList.vue";

export default {
  name: "Dashboard",
  components: {
    UserList,
    VflSidepanel,
    UserProfile,
    WhoNeedsAttention,
    QuickAssess,
    AccountBanner,
    DashboardAssessmentCards,
    AnalyticsTable,
    InviteUserToAssessmentDialog,
    AdminTour,
    FeatureIntroductionCard,
    TotalSeatsWarningBar,
    UserHistoryTable,
    DashboardDeactivatedUserBanner,
    AiAssistantContainer,
    PainOverview
  },
  data() {
    return {
      welcomeDialog: true,
      incompleteAssessment: {},
      loading: true,
      simsQuickAssess: false,
      webcamQuickAssess: false,
      showAccountBanner: false,
      preloadedInviteUser: null,
      assessmentCardClicked: ""
    };
  },
  assistantTypes: AssistantTypes,
  computed: {
    ...mapGetters({
      currentUserTeamAndSubscriptionBasicInformation:
        "currentUserTeamAndSubscriptionBasicInformation",
      userEmail: "userEmail",
      userName: "userName",
      teamMemberId: "teamMemberId",
      burnout: "burnout",
      deactivatedUser: "deactivatedUser",
      featureIntros: "featureIntros",
      isTrialUser: "isTrialUser",
      subscriptionHasExpired: "subscriptionHasExpired",
      modalOpen: "modalOpen",
      driverAssessmentEnabled: "driverAssessmentEnabled",
      enableWhoNeedsAttentionTable: "enableWhoNeedsAttentionTable",
      isSuperAdmin: "isSuperAdmin",
      hideWhoNeedsAttentionTable: "hideWhoNeedsAttentionTable",
      adminId: "userId"
    }),
    ...mapGetters("ai", ["assistantType"]),
    ...mapGetters("adminDashboard", ["dashboardState"]),
    ...mapGetters("sidePanelStore", {
      sidePanelActive: "active",
      selectedPersonId: "selectedPersonId",
      sidePanelViewMode: "viewMode",
      sidePanelHeading: "heading",
      showSidePanelBackButton: "showBackButton"
    }),
    inviteDialog: {
      get() {
        return this.assessmentCardClicked;
      },
      set(inviteDialogNeeded) {
        if (
          !inviteDialogNeeded &&
          this.$refs.userHistory &&
          !this.assessmentCardClicked !==
            Constants.assessmentTypes.burnoutAssessment
        ) {
          this.$refs.userHistory.getUserHistory();
        }
        this.assessmentCardClicked = "";
      }
    },
    showDashboardAssistant() {
      return this.isSuperAdmin && this.assistantType !== AssistantTypes.REPORT;
    },
    showWelcomeDialog() {
      return (
        !!this.currentUserTeamAndSubscriptionBasicInformation &&
        this.currentUserTeamAndSubscriptionBasicInformation.isNew
      );
    },
    inviteDialogWidth() {
      return this.$vuetify.breakpoint.mdAndDown ? "90%" : "70%";
    },
    inviteAssessmentType() {
      const validTypes = Object.values(Constants.assessmentTypes);

      return validTypes.includes(this.assessmentCardClicked)
        ? this.assessmentCardClicked
        : "";
    },
    showAdminFeedbackPopup() {
      const englishLocale = this._i18n.locale.includes("en");
      if (!englishLocale) {
        return false;
      }

      const shouldShowCustomRecs = NewFeatureService.showFeature(
        "AdminDashboardFeedback"
      );
      return shouldShowCustomRecs;
    },
    visibleDashboardGridComponentsCount() {
      return [
        true, // pain overview card is always shown
        !this.hideWhoNeedsAttentionTable,
        true // analytics-table is always visible
      ].filter(Boolean).length;
    },
    isAnalyticsTableFullWidth() {
      return (
        this.visibleDashboardGridComponentsCount === 1 ||
        this.visibleDashboardGridComponentsCount === 3
      );
    },
    sidePanelTitle() {
      return this.sidePanelViewMode === "list" ? this.sidePanelHeading : "";
    }
  },
  async mounted() {
    this.updatePageTitle();

    try {
      let teamMberInfo = await TeamService.getTeamMemberInfo();
      await this.getUserTeamAndSubscriptionBaseInformation({
        memberId: this.teamMemberId,
        forceToUpdateExistedInformation: true
      });

      // TODO: add back in this.IsTrialUser to show account banner (hidden for week of 17/06/24)
      this.showAccountBanner = this.subscriptionHasExpired;

      let teamMember = await getTeamMember(this.teamMemberId);

      if (teamMember.disabledAt !== null) {
        Auth.signOut().then(() => {
          this.resetLoginState();
          this.$router.push("/", () => {});
        });
      }

      let featureIntrosNeedsLoading =
        !this.featureIntros ||
        (this.featureIntros &&
          (this.featureIntros.length == 0 ||
            this.featureIntros[0].userId !== teamMberInfo.userId));

      if (featureIntrosNeedsLoading) {
        await FeatureService.getActiveFeatureIntros();
      }

      updateCheckListFromFeatures(this.featureIntros);
    } catch (err) {
      this.$logger.captureException(err);
    } finally {
      this.loading = false;
    }
  },
  destroyed() {
    this.resetSidePanelState();
  },
  methods: {
    ...mapActions("layout", ["setPageTitle"]),
    ...mapActions([
      "getUserTeamAndSubscriptionBaseInformation",
      "resetLoginState"
    ]),
    ...mapMutations(["setTeamMemberId"]),
    ...mapActions("sidePanelStore", {
      openSidePanel: "open",
      closeSidePanel: "close",
      resetSidePanelState: "reset",
      sidePanelGoBack: "goBackToList"
    }),
    ...mapActions("userProfile", { resetUserProfile: "reset" }),
    showInviteWithPreloadedUser(user) {
      this.assessmentType = Constants.assessmentTypes.deskAssessment;
      this.preloadedInviteUser = user;
      this.assessmentCardClicked = this.assessmentType;
    },
    onAssessmentCardClicked(newValue) {
      this.assessmentCardClicked = newValue;
    },
    openFeedbackSurvey() {
      window.open("https://tally.so/r/w24EOL", "_blank");
    },
    scrollToDashboardElement(id) {
      const element = document.getElementById(id);
      if (element) {
        const headerOffset = 64;
        const elementPosition = element.getBoundingClientRect().top;

        const offsetPosition =
          elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      }
    },
    openUserProfile(data) {
      this.openSidePanel(data.id);

      sendEvent("MSK passport opened", {
        from: "History table",
        end_user: this.selectedPersonId,
        admin: this.adminId
      });
    },
    handleSidePanelChange(isOpen) {
      if (!isOpen) {
        this.closeSidePanel();
      }
    },
    handleSidePanelCloseComplete() {
      this.resetSidePanelState();
    },
    handleSidePanelBackClick() {
      this.resetUserProfile();
      this.sidePanelGoBack();
    },
    updatePageTitle() {
      if (typeof this.userName === "string" && this.userName.trim()) {
        const firstName = this.userName.trim().split(/\s+/)[0];
        this.setPageTitle(
          this.$t("pageTitle.welcomeUser", { name: firstName })
        );
      } else {
        this.setPageTitle(this.$t("pageTitle.welcome"));
      }
    }
  },
  watch: {
    inviteDialog: function (newVal) {
      if (newVal === true) {
        return;
      }
      if (this.$refs.historyTable != undefined) {
        this.$refs.historyTable.updateHistory();
      }
      if (this.$refs.analyticsTable != undefined) {
        this.$refs.analyticsTable.updateAnalytics();
      }
      this.getUserTeamAndSubscriptionBaseInformation({
        memberId: this.teamMemberId,
        forceToUpdateExistedInformation: true
      });
    },
    dashboardState: {
      handler(newState) {
        if (newState.scrollTargetId) {
          this.scrollToDashboardElement(newState.scrollTargetId);
        }
      },
      deep: true
    }
  }
};
</script>

<style scoped lang="scss">
.dashboard-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  grid-row-gap: 3rem;
  margin-bottom: 3rem;
  margin-top: 3rem;

  // The analytics cards should be full width if the other cards are not being shown, OR BOTH the other cards are shown
  .analytics-card {
    &:nth-child(2n + 1) {
      grid-column: 1/-1;
    }
  }
}
@container page-container (max-width: 1000px) {
  .dashboard-grid {
    display: block;

    > * + * {
      margin-top: 3rem !important;
    }
  }
}
</style>
