<template>
  <div class="ai-search-suggestions d-flex align-center">
    <button v-if="showLeftArrow" @click="scrollLeft" class="arrow left">
      <v-icon>mdi-chevron-left</v-icon>
    </button>
    <div
      ref="scrollContainer"
      class="scroll-container flex-grow-1"
      :class="{
        'has-gradient-left': showGradientLeft,
        'has-gradient-right': showGradientRight
      }"
      @scroll="onScroll"
    >
      <button
        v-for="item in items"
        :key="item.id"
        class="vfl-button-ai-outline is-small"
        @click="() => search(item.name)"
      >
        {{ item.name }}
      </button>
    </div>
    <button v-if="showRightArrow" @click="scrollRight" class="arrow right">
      <v-icon>mdi-chevron-right</v-icon>
    </button>
  </div>
</template>

<script>
export default {
  name: "AiSearchSuggestions",
  data() {
    return {
      showLeftArrow: false,
      showRightArrow: false,
      items: [
        { name: this.$t("aiAssistant.seedQuestions.search.atRisk") },
        {
          name: this.$t("aiAssistant.seedQuestions.search.moreThan10Risks")
        },
        {
          name: this.$t("aiAssistant.seedQuestions.search.lowScoreHome")
        },
        {
          name: this.$t(
            "aiAssistant.seedQuestions.search.notUsingMouseOrKeyboard"
          )
        },
        {
          name: this.$t(
            "aiAssistant.seedQuestions.search.mskIssueWithBelowAverageScore"
          )
        }
      ]
    };
  },
  computed: {
    showGradientRight() {
      return this.showRightArrow;
    },
    showGradientLeft() {
      return this.showLeftArrow;
    }
  },
  mounted() {
    this.checkArrows();
  },

  methods: {
    scrollLeft() {
      const container = this.$refs.scrollContainer;

      container.scrollBy({ left: -200, behavior: "smooth" });
    },
    scrollRight() {
      const container = this.$refs.scrollContainer;

      container.scrollBy({ left: 200, behavior: "smooth" });
    },
    onScroll() {
      this.checkArrows();
    },
    checkArrows() {
      const container = this.$refs.scrollContainer;

      this.showLeftArrow = container.scrollLeft > 0;
      this.showRightArrow =
        container.scrollWidth > container.clientWidth + container.scrollLeft;
    },

    search(item) {
      this.$emit("onSearch", item);
    }
  }
};
</script>

<style scoped lang="scss">
.ai-search-suggestions {
  position: relative;
}

.scroll-container {
  display: flex;
  gap: 0.5rem;
  overflow-x: auto;
  padding-bottom: 0.5rem;
  scroll-behavior: smooth;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none;
  }

  &:before,
  &:after {
    content: "";
    height: 34px; // button height
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100px;
  }

  &.has-gradient-left:before {
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0), white);
    opacity: 1;
    left: 0;
  }

  &.has-gradient-right:after {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), white);
    opacity: 1;
    right: 0;
  }
}

.vfl-button-ai-outline {
  font-weight: 400;

  &:active {
    // The tags can be quite long, so the default scale effect looks too dramatic. Overriding here
    transform: scale(0.995, 0.98);
  }
}

.arrow {
  cursor: pointer;
  background-color: #eaf1f9;
  border-radius: 8px;
  position: absolute;
  top: 4px;
  user-select: none;
  z-index: 1;

  // Increase hit size
  &:before {
    content: "";
    bottom: -6px;
    left: -6px;
    right: -6px;
    position: absolute;
    top: -6px;
  }

  &:hover {
    filter: brightness(95%);
  }

  &.left {
    left: -2rem;
  }

  &.right {
    right: -2rem;
  }
}
</style>
