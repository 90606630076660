<template>
  <v-navigation-drawer
    id="navDrawer"
    :key="`mini:${isMinimised}`"
    v-model="drawer"
    color="vflVitruvian"
    clipped
    app
    touchless
    height="100%"
    :width="'200px'"
    :mini-variant="isMinimised"
    style="overflow: visible; z-index: 21"
    :permanent="$vuetify.breakpoint.mdAndUp"
  >
    <template v-slot:prepend>
      <vitrue-new-feature
        v-if="showNavV3Tooltip"
        v-model="navV3Tooltip"
        :text="$t('tooltips.newFeatures.navigationV3')"
        style="top: 400px"
        :width="$vuetify.breakpoint.smAndDown ? 250 : 500"
        @close="navV3Tooltip = false"
        id="NavigationV3"
      />
      <div class="toggle-button" v-if="$vuetify.breakpoint.mdAndUp">
        <button
          @click.stop="isMinimised = !isMinimised"
          class="vfl-button-primary is-icon-only is-small"
        >
          <span class="d-sr-only">{{ $t("buttons.toggleSidebar") }}</span>
          <v-icon>{{
            isMinimised ? "mdi-chevron-right" : "mdi-chevron-left"
          }}</v-icon>
        </button>
      </div>

      <team-logo-and-name
        v-if="signedIn"
        :class="isMinimised ? `pa-3 pt-5` : `pa-4`"
        :variant="isMinimised ? `minimal` : `full`"
      />

      <div
        :class="showHeadings ? 'px-4 pt-2' : ''"
        class="font-weight-medium black--text"
        v-for="group in buttonGroups"
        :key="group.key"
      >
        <div v-if="group.show">
          <p
            v-show="showHeadings"
            class="text-body-1 mb-0 text-overline"
            style="cursor: default; color: #f2f5f5; line-height: 1.5"
          >
            {{ group.heading }}
          </p>
          <v-list
            :class="
              isMinimised && group.key === 'personal' && !isSimpleUser
                ? 'mt-14'
                : ''
            "
          >
            <div v-for="button in group.buttons" :key="button.key" class="">
              <v-list-item
                v-if="button.show"
                link
                @click="navigate(button)"
                class="text-body-1 sidebar-list-item"
                :ripple="false"
                :class="{ 'is-active': activeTab === button.key }"
              >
                <v-list-item-icon>
                  <v-icon size="20">{{ button.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content
                  v-if="!isMinimised"
                  :class="[{ 'd-sr-only': isMinimised }]"
                >
                  <v-list-item-title class="text-small">{{
                    button.title
                  }}</v-list-item-title>
                </v-list-item-content>
                <vitrue-onboarding-tooltip
                  v-if="
                    showOnboardingToolTip(
                      button.key,
                      'PersonalDashboard',
                      'viewPainCoach'
                    )
                  "
                  position="right"
                  :text="$t('onboarding.adminCheckList.tooltips.viewPainCoach')"
                  :width="250"
                />
                <vitrue-onboarding-tooltip
                  v-if="
                    showOnboardingToolTip(
                      button.key,
                      'Insights',
                      'viewTeamData'
                    )
                  "
                  position="right"
                  :text="$t('onboarding.adminCheckList.tooltips.viewTeamData')"
                  :width="250"
                />
              </v-list-item>
            </div>
          </v-list>
        </div>
      </div>
    </template>

    <template v-slot:append>
      <div :class="isMinimised ? 'px-2' : 'px-4'" class="mb-6">
        <site-feedback :has-text="!isMinimised" class="mb-8" />
      </div>

      <div :class="isMinimised ? `pa-3` : `pa-4`">
        <vida-logo :is-icon="isMinimised" is-dark />
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import VitrueOnboardingTooltip from "@/components/common/VitrueOnboardingTooltip.vue";
import AvatarIcon from "@/components/common/AvatarIcon.vue";
import NewFeatureService from "@/services/new-feature-service.js";
import VitrueNewFeature from "@/components/common/VitrueNewFeature.vue";
import SiteFeedback from "@/components/layout/SiteFeedback.vue";
import TeamLogoAndName from "@/components/layout/TeamLogoAndName.vue";
import VidaLogo from "@/components/layout/VidaLogo.vue";

export default {
  name: "side-bar",
  components: {
    VidaLogo,
    TeamLogoAndName,
    SiteFeedback,
    VitrueOnboardingTooltip,
    AvatarIcon,
    VitrueNewFeature
  },
  props: {
    value: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      drawer: null,
      activeTab: "adminDashboard",
      navV3Tooltip: true,
      isMinimised: false
    };
  },
  created() {
    this.setActiveTab();
  },
  methods: {
    ...mapActions(["resetLoginState"]),
    navigate(item) {
      if (!item.path) {
        return;
      }
      if (
        item.path === "/wellness" &&
        this.$route.path.includes("deskasssessment")
      ) {
        this.$mixpanel.track(`Go to dashboard after Desk assessment`, {
          fromSidebar: true
        });
      }
      this.$mixpanel.track(`New side bar navigation`, {
        route: item.path
      });
      this.$router.push(item.path, () => {});
      this.$vuetify.goTo(0);
      this.activeTab = item.key;
    },
    showOnboardingToolTip(page, tooltipPage, tooltip) {
      return (
        page === tooltipPage && this.onboardingChecklist.hoveredItem === tooltip
      );
    },
    setActiveTab(to) {
      var path = to ? to.fullPath : this.$route.path;
      var topTabs = this.buttonGroups.reduce(
        (acc, item) => acc.concat(item.buttons),
        []
      );
      var allTabs = [...topTabs];
      var tabs = allTabs.map(x => x.subSections ?? x).flat();
      var activeTab = tabs.find(x => path.includes(x.path));
      this.activeTab = activeTab?.key;
    }
  },
  computed: {
    ...mapGetters([
      "isSimpleUser",
      "teamId",
      "teamIdpName",
      "ssoSignIn",
      "onboardingChecklist",
      "showDummySidebar",
      "isSuperAdmin",
      "enableUserPassport",
      "subscriptionExpired",
      "enableEmployeesPage",
      "signedIn"
    ]),
    endUserOrNotLoggedIn() {
      return this.isSimpleUser || this.showDummySidebar;
    },
    showHeadings() {
      return !this.isMinimised && !this.endUserOrNotLoggedIn;
    },
    buttonGroups() {
      return [
        {
          key: "team",
          show: !this.endUserOrNotLoggedIn,
          heading: "Team",
          buttons: [
            {
              key: "AdminDashboard",
              icon: "grid_view",
              path: "/dashboard",
              title: this.$t("layout.sidebar.dashboard"),
              show: true
            },
            {
              key: "EmployeesD",
              icon: "mdi-account-multiple-outline",
              path: "/employees-d",
              title: "Employees",
              show: this.enableUserPassport
            },
            {
              key: "Employees",
              icon: "mdi-account-multiple-outline",
              path: "/people",
              title: "People",
              show: this.enableEmployeesPage
            },
            {
              key: "Insights",
              icon: "mdi-star-four-points-outline",
              path: "/analytics",
              title: this.$t("layout.sidebar.insights"),
              show: !this.subscriptionExpired
            },
            {
              key: "Suggestions",
              icon: "mdi-list-status",
              path: "/suggestions",
              title: this.$t("layout.sidebar.suggestions"),
              show: !this.subscriptionExpired
            },
            {
              key: "Certification",
              icon: "mdi-license",
              path: "/certification",
              title: this.$t("layout.sidebar.regulatory"),
              show: !this.subscriptionExpired
            },
            {
              key: "Users",
              icon: "mdi-account-multiple-outline",
              path: "/users",
              title: this.$t("layout.sidebar.users"),
              show: !this.subscriptionExpired
            }
          ]
        },
        {
          key: "personal",
          show: true,
          heading: "Personal",
          buttons: [
            {
              key: "PersonalDashboard",
              path: "/wellness",
              icon: "mdi-gymnastics",
              title: this.$t("layout.sidebar.wellbeing"),
              show: true
            },
            {
              key: "Reports",
              icon: "mdi-clipboard-check-outline",
              path: "/reports-archive",
              title: this.$t("layout.sidebar.reports"),
              show: !this.subscriptionExpired
            }
          ]
        }
      ];
    },
    showNavV3Tooltip() {
      let showTooltip = NewFeatureService.showFeature("NavigationV3");
      return showTooltip && this.navV3Tooltip;
    }
  },
  watch: {
    $route(to) {
      this.setActiveTab(to);
    },
    value() {
      this.drawer = this.value;
    },
    drawer() {
      this.$emit("input", this.drawer);
    }
  }
};
</script>

<style scoped lang="scss">
.toggle-button {
  border-radius: 999px;
  box-shadow: 1px 1px 4px 0px rgb(0 95 97 / 34%);
  display: grid;
  height: 24px;
  place-content: center;
  opacity: 0;
  position: absolute;
  right: -12px;
  top: 24px;
  transition: 0.125s opacity ease-in-out;
  width: 24px;
  z-index: 11;

  button {
    background-color: var(--v-vflAquaLight1-base);
    border: none;
    color: var(--v-vflPrimary-base);
    min-width: 32px;

    &:before {
      bottom: -10px;
      content: "";
      left: -10px;
      position: absolute;
      right: -10px;
      top: -10px;
    }
  }

  &:hover {
    pointer-events: auto;
  }
}

#navDrawer:hover .toggle-button {
  opacity: 1;
  pointer-events: auto;
}

::v-deep .v-list-group--sub-group .v-list-group__header {
  padding-left: 16px !important;
}

* {
  scrollbar-width: thin;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: lightgray;
  border-radius: 20px;
  border: 5px solid lightgray;
}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #f2f5f5;

  &.is-active {
    color: var(--v-vflTurquoiseLight-base);
    font-weight: 700 !important;
  }
}

.v-list-item--link:before {
  color: #012828;
}

.v-list-item__content {
  position: relative;
}

.sidebar-list-item {
  min-height: initial;
  padding: 0.5rem 1rem;

  &:hover {
    &:before {
      opacity: 1;
    }
  }

  .v-list-item__icon {
    margin: 0 4px 0 0;

    i {
      color: inherit;
    }
  }

  .v-list-item__content {
    line-height: 1.35;
    padding: 0;
  }
}

.is-active {
  color: var(--v-vflTurquoiseLight-base);
}
</style>
