<!-- VflIconButton.vue -->
<template>
  <VflButton
    v-bind="$props"
    :class="['vfl-icon-button', `is-${size}`]"
    @click="handleClick"
    ><span class="d-sr-only">{{ screenReader }}</span>
    <slot></slot>
  </VflButton>
</template>

<script>
import VflButton from "./VflButton.vue";

export default {
  name: "VflIconButton",
  components: {
    VflButton
  },
  props: {
    ...VflButton.props,
    screenReader: {
      type: String,
      required: true
    }
  },
  methods: {
    handleClick(event) {
      if (!this.disabled) {
        this.$emit("click", event);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.vfl-icon-button {
  aspect-ratio: 1/1;
  padding: 0 !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.is-small {
    width: 24px;
    height: 24px;
    min-width: 24px;
  }

  &.is-medium {
    width: 40px;
    height: 40px;
    min-width: 40px;
  }

  &.is-large {
    width: 56px;
    height: 56px;
    min-width: 56px;
  }
}
</style>
