﻿<template>
  <div class="fill-height">
    <v-dialog
      v-model="feedbackDialog"
      v-if="feedbackDialog"
      :max-width="$vuetify.breakpoint.smAndDown ? '100%' : '480px'"
      content-class="mx-4"
    >
      <feedback-dialog
        v-if="enableGeneralFeedback"
        v-model="feedbackDialog"
        :assessmentId="assessmentId"
        :urlSuffix="currentTheme"
      />
    </v-dialog>

    <drivers-pre-assessment-checklist v-if="showPreAssessmentChecklist" />

    <driver-incomplete-assessment-dialog
      v-model="showIncompleteAssessmentDialog"
      @input="$emit('input', $event)"
      @startNew="incompleteAssessmentDialogSetOpened"
      :assessmentId="incompleteAssessmentId"
      :assessmentType="assessmentType"
    />

    <loader
      v-if="loading"
      data-test-id="driver-assessment-loader"
      :trigger="loading"
      @close="loading = saving"
    />

    <div
      v-else-if="currentTheme && activeAsyncRequestsCount === 0"
      class="mx-auto"
      :class="{
        'pb-12': !isReport && currentTheme !== $options.steps.Welcome.THEME,
        'px-4 pt-4': currentTheme !== $options.steps.Welcome.THEME
      }"
      data-test-id="driver-assessment-container"
      ref="container"
      :style="containerStyle"
    >
      <vfl-stepper
        class="mt-md-4"
        v-if="currentStepObj.STEPPER"
        :steps="stepper"
        :currentStep="currentPageIndex"
      />

      <driver-assessment-landing
        v-if="currentTheme === $options.steps.Welcome.THEME"
        @next="onNext"
      />

      <assessment-form
        v-if="currentStepObj.FORM"
        :key="currentTheme"
        :schema="currentSchema"
        v-model="results[currentTheme]"
        :ref="currentTheme"
        :isPainTheme="currentTheme === $options.steps.Pain.THEME"
        :results="results"
        :currentTheme="currentTheme"
        :assessmentType="assessmentType"
      />

      <driver-report
        v-if="currentTheme === $options.steps.Report.THEME"
        :results="results"
        :assessmentId="assessmentId"
        reportContext="assessment"
      />
      <navigation
        v-if="currentStepObj.NAVIGATION"
        @previous="onPrevious"
        @next="onNext"
        :loading="nextClicked"
      />
    </div>

    <feedback-button
      v-if="enableGeneralFeedback"
      v-model="feedbackDialog"
      :style="$vuetify.breakpoint.smAndDown ? '' : 'top:50%;right: 0%'"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AssessmentForm from "@/components/assessment/AssessmentForm.vue";
import Report from "@/components/driver-assessment/report/DriverReport.vue";
import Navigation from "@/components/driver-assessment/DriverAssessmentNavigation.vue";
import VflStepper from "@/components/common/VflStepper.vue";
import {
  DriverAssessmentSteps,
  getStepByIndex
} from "@/components/driver-assessment/constants/index.js";
import DriverReport from "@/components/driver-assessment/report/DriverReport.vue";
import Loader from "@/components/driver-assessment/DriverAssessmentLoader.vue";
import DriverIncompleteAssessmentDialog from "@/components/common/assessments/IncompleteAssessmentDialog.vue";
import DriversPreAssessmentChecklist from "@/components/driver-assessment/DriversPreAssessmentChecklist.vue";
import LoadingService from "@/services/assessment-loading.js";
import { assessmentTypes } from "@/constants/constants.js";
import SaveAssessment from "@/components/common/assessments/save-assessment-service.js";
import { GetAssessmentResults } from "@/components/common/assessments/get-assessment-service.js";
import { preFillResultsForTesting } from "./utils/index.js";
import {
  updateWellnessProgrammeWithPain,
  getInProgressAssessment,
  sendAssessmentCompleteNotification,
  addAssessmentRecommendations
} from "@/customApi";
import { getRankedPainAreaDtosOrDefault } from "@/services/assessment/pain/pain-rating-service.js";
import ScoreService from "@/services/driver-assessment/driver-score-service.js";
import FeedbackButton from "@/components/common/Feedback/FeedbackButton.vue";
const FeedbackDialog = () =>
  import("@/components/common/Feedback/FeedbackDialog.vue");
import { getSchema } from "@/services/assessment/get-schema-service.js";
import { getRecommendations } from "@/components/driver-assessment/report/recommendations/report-recommendation-generator.js";
import DriverAssessmentLanding from "@/components/assessment/DriverAssessmentLanding.vue";

export default {
  name: "DriverAssessment",
  components: {
    DriverAssessmentLanding,
    AssessmentForm,
    Report,
    Navigation,
    VflStepper,
    DriverReport,
    Loader,
    DriverIncompleteAssessmentDialog,
    FeedbackButton,
    FeedbackDialog,
    DriversPreAssessmentChecklist
  },
  props: {
    assessmentId: String
  },
  steps: DriverAssessmentSteps,
  data() {
    return {
      loading: false,
      saving: false,
      assessmentType: assessmentTypes.driverAssessment,
      currentPageIndex: -1,
      stepper: null,
      results: {
        vehicle: {},
        drivingPosture: {},
        habits: {},
        pain: {
          areas: {}
        },
        health: {}
      },
      showIncompleteAssessmentDialog: false,
      incompleteAssessmentId: null,
      feedbackDialog: false,
      nextClicked: false
    };
  },
  created() {
    if (this.disableAssessments) {
      this.$router.push("/wellness");
    }
    this.schema = getSchema(assessmentTypes.driverAssessment);
    this.buildSteps();
  },
  mounted() {
    this.startAssessment();
  },
  beforeDestroy() {
    this.configureLayoutForAssessmentReport({
      condition: false,
      pageTitle: null
    });
  },
  computed: {
    ...mapGetters([
      "driverAssessmentEnabled",
      "activeAsyncRequestsCount",
      "prefillDriverAssessmentResponses",
      "signedIn",
      "driversPreAssessmentChecklist",
      "disableAssessments",
      "signedIn"
    ]),
    showPreAssessmentChecklist() {
      if (!this.driversPreAssessmentChecklist) return false;
      if (this.showIncompleteAssessmentDialog) return false;
      if (this.loading) return false;

      return true;
    },
    currentStepObj() {
      return getStepByIndex(this.currentPageIndex);
    },
    currentTheme() {
      return this.currentStepObj?.THEME || "welcome";
    },
    currentSchema() {
      return this.schema.find(({ theme }) => theme === this.currentTheme);
    },
    onLastPage() {
      const endIndex = this.$options.steps.Report.INDEX;
      return this.currentStepObj.INDEX === endIndex - 1;
    },
    score() {
      if (!this.onLastPage) return null;
      return ScoreService.getOverallScore(this.results);
    },
    isForm() {
      const welcome = this.currentTheme === this.$options.steps.Welcome.THEME;

      return !welcome && !this.isReport;
    },
    isReport() {
      return this.currentTheme === this.$options.steps.Report.THEME;
    },
    containerStyle() {
      return {
        width: this.isForm ? `var(--assessment-form-width)` : "",
        maxWidth: "100%"
      };
    },
    enableGeneralFeedback() {
      return !this.signedIn;
    }
  },
  watch: {
    currentPageIndex() {
      this.scrollTop();
      this.handlePageLayout();
    },
    assessmentId(val) {
      if (val) {
        this.startAssessment();
      }
    }
  },
  methods: {
    ...mapActions("layout", ["configureLayoutForAssessmentReport"]),
    async startAssessment() {
      const setToOpened = !this.incompleteAssessmentId;
      const getResults = false;

      const assessmentState = await LoadingService.getAssessmentStateAndResults(
        this.assessmentId,
        this.assessmentType,
        setToOpened,
        getResults
      );

      if (assessmentState.redirectObject) {
        this.$router.push(assessmentState.redirectObject);
        return;
      }

      if (assessmentState.assessmentStarted) {
        this.results = await GetAssessmentResults(
          this.assessmentId,
          this.assessmentType,
          this.schema
        );
      } else if (import.meta.env.DEV || this.prefillDriverAssessmentResponses) {
        this.results = preFillResultsForTesting();
      }
      if (assessmentState.assessmentComplete) {
        this.currentPageIndex = this.$options.steps.Report.INDEX;
      } else {
        await this.checkForInProgressAssessment();
      }
    },
    scrollTop() {
      window.scrollTo(0, 0);
    },
    onPrevious() {
      this.currentPageIndex--;
    },
    async onNext() {
      if (this.nextClicked) return;
      try {
        this.nextClicked = true;
        await this.goToNextPage();
      } catch (e) {
        this.$logger.captureException(e);
      } finally {
        this.nextClicked = false;
      }
    },
    async goToNextPage() {
      if (!this.currentStepObj.VALIDATION || this.isValid()) {
        await this.saveAssessment();
        this.currentPageIndex++;
        this.$mixpanel.track("Driver assessment", {
          step: `Go to ${this.currentTheme}`
        });
      }
    },
    async saveAssessment() {
      var results = this.results[this.currentTheme];
      if (!results) {
        return;
      }
      this.saving = true;
      this.loading = this.onLastPage;
      await SaveAssessment(
        this.assessmentId,
        results,
        this.assessmentType,
        this.currentSchema,
        this.score
      );

      if (this.onLastPage) {
        const painAreasDto = getRankedPainAreaDtosOrDefault(
          this.results,
          assessmentTypes.driverAssessment
        );
        await updateWellnessProgrammeWithPain(this.assessmentId, painAreasDto);
        await sendAssessmentCompleteNotification(this.assessmentId);
        await this.saveRecommendations();
        this.loading = false;
      }
      this.saving = false;
    },
    isValid() {
      return this.$refs[this.currentTheme].isValid();
    },
    buildSteps() {
      this.stepper = this.schema
        .filter(({ theme }) => theme !== this.$options.steps.Vehicle.THEME)
        .map(({ stepperTitle }) => this.$t(stepperTitle));
    },
    async incompleteAssessmentDialogSetOpened() {
      await LoadingService.setAssessmentToOpened(
        this.assessmentId,
        this.assessmentType
      );
    },
    async checkForInProgressAssessment() {
      if (!this.signedIn) {
        return;
      }
      try {
        this.incompleteAssessmentId = await getInProgressAssessment(
          assessmentTypes.driverAssessment
        );
      } catch (err) {
      } finally {
        this.showIncompleteAssessmentDialog =
          !!this.incompleteAssessmentId &&
          this.incompleteAssessmentId !== this.assessmentId;
      }
    },
    async saveRecommendations() {
      const recommendations = getRecommendations(this.results);
      const dto = {
        assessmentId: this.assessmentId,
        recommendations: recommendations.map(x => x.id)
      };

      await addAssessmentRecommendations(dto);
    },
    handlePageLayout() {
      this.configureLayoutForAssessmentReport({
        condition: this.isReport,
        pageTitle: this.$t("pageTitle.driverAssessmentReport")
      });
    }
  }
};
</script>
