<template>
  <div>
    <h3 class="mb-4" :class="isFullWidth ? 'text-h5' : 'text-subtitle-1'">
      {{ $t("analytics.tabs.analytics") }}
    </h3>

    <v-row justify="space-between" class="d-flex">
      <v-col
        cols="6"
        sm="6"
        :md="isFullWidth ? 3 : 6"
        v-for="card in cards"
        :key="card.primaryText"
      >
        <analytics-tile
          :primaryLoaded="loaded"
          :secondaryLoaded="loaded"
          :primaryNumberValue="card.primaryNumberValue"
          :primaryTextValue="card.primaryTextValue"
          :primaryText="card.primaryText"
          :secondaryValue="card.secondaryValue"
          :secondaryText="card.secondaryText"
          :secondaryColour="card.secondaryColour"
          :primaryPercentage="card.primaryPercentage"
          :secondaryPercentage="card.secondaryPercentage"
          :reverseSecondaryColours="card.reverseSecondaryColours"
          :showSecondary="card.showSecondary"
          :toolTipText="card.toolTipText"
          :hasLongTitleText="card.hasLongTitleText"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AnalyticsTile from "./AnalyticsTile.vue";
import { getAllSummaryData } from "@/customApi";
import { mapGetters } from "vuex";
import BurnoutScores from "@/services/burnout/burnout-scores.js";

export default {
  name: "analytics-table",
  components: {
    AnalyticsTile
  },
  props: {
    isFullWidth: Boolean
  },
  data() {
    return {
      loaded: false,
      stats: {
        totalAssessmentsSent: null,
        monthAssessmentsSent: null,
        totalAssessmentsComplete: null,
        monthAssessmentsComplete: null,
        averageDeskScore: null,
        averageDeskScoreChange: null,
        averageBurnoutScore: null,
        averageBurnoutScoreChange: null
      }
    };
  },
  async mounted() {
    this.updateAnalytics();
  },
  computed: {
    ...mapGetters(["userEmail"]),
    cards() {
      return [
        {
          primaryNumberValue: this.stats.totalAssessmentsSent,
          primaryTextValue: null,
          primaryText: this.$t("analytics.dashboardTable.testsSent"),
          primaryPercentage: false,
          secondaryValue: this.stats.monthAssessmentsSent,
          secondaryText: this.$t("analytics.dashboardTable.thisMonth"),
          secondaryColour: "primary",
          secondaryPercentage: false,
          reverseSecondaryColours: false
        },
        {
          primaryNumberValue: this.stats.totalUsersWithCompleteDeskAssessments,
          primaryTextValue: null,
          primaryText: this.$t("analytics.dashboardTable.testsComplete"),
          primaryPercentage: false,
          secondaryValue: this.stats.monthUsersWithCompleteDeskAssessments,
          secondaryText: this.$t("analytics.dashboardTable.thisMonth"),
          secondaryColour: "primary",
          secondaryPercentage: false,
          reverseSecondaryColours: false
        },
        {
          primaryNumberValue: this.stats.averageDeskScore,
          primaryTextValue: null,
          primaryText: this.$t("analytics.dashboardTable.deskScore"),
          primaryPercentage: true,
          secondaryValue: this.stats.deskScoreDifferenceFromPopulationAverage,
          secondaryText: this.$t(
            "analytics.dashboardTable.populationComparison"
          ),
          secondaryColour: "primary",
          secondaryPercentage: true,
          reverseSecondaryColours: false
        },
        {
          primaryNumberValue: null,
          primaryTextValue: this.stats.averageBurnoutScore
            ? BurnoutScores.getRiskTextFromScore(
                this.stats.averageBurnoutScore,
                "overall"
              )
            : null,
          primaryText: this.$t("analytics.dashboardTable.resilienceScore"),
          primaryPercentage: false,
          toolTipText: this.$t("tooltips.burnoutAnalytics"),
          hasLongTitleText: true
        }
      ];
    }
  },

  methods: {
    async updateAnalytics() {
      const vm = this;
      try {
        this.loaded = false;
        getAllSummaryData().then(function (response) {
          vm.stats = response;
        });
      } catch (error) {
        this.$logger.captureException(error);
        console.error("Getting analytics failed: ", error);
      } finally {
        this.loaded = true;
      }
    }
  }
};
</script>
