<template>
  <div>
    <v-row justify="space-between" align="center" class="mb-4">
      <v-col cols="12" md="6" align-self="center">
        <v-text-field
          v-model="filters.search"
          class="pt-0 mr-auto"
          :label="`${$t('buttons.searchUser')}...`"
          hide-details
          prepend-inner-icon="mdi-magnify"
          color="var(--v-vflPrimary-base)"
        />
      </v-col>
      <v-col cols="12" md="4">
        <div
          class="d-flex align-center flex-wrap flex-md-nowrap justify-end"
          style="gap: 1.5rem"
        >
          <button
            v-if="$vuetify.breakpoint.xs"
            class="has-icon is-small mr-auto"
            :class="
              showFilterBar
                ? 'vfl-button-primary'
                : 'vfl-button-primary-outline'
            "
            @click="showFilterBar = !showFilterBar"
          >
            <v-icon
              :color="showFilterBar ? 'white' : 'var(--v-vflPrimary-base)'"
              >mdi-filter-variant</v-icon
            >
            {{ $t("buttons.filter") }}
          </button>
          <v-checkbox
            v-model="filters.showArchived"
            :hide-details="true"
            :label="$t('buttons.showArchived')"
            class="mt-0 pt-0 archived-checkbox"
            :class="filters.showArchived ? 'active' : ''"
            @change="onShowArchivedChange"
          />
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        v-if="showFilterBar || $vuetify.breakpoint.smAndUp"
        cols="12"
        align-self="center"
      >
        <v-row>
          <v-col cols="12" sm="6" lg="2" class="d-flex">
            <table-filter
              v-model="filters.location"
              class="pt-0 table-filter"
              :items="Object.values(locations)"
              label="dashboardHistoryComponents.assessmentHistoryTable.labels.location"
              chips
              outlineChips
              :checkBox="false"
            />
          </v-col>
          <v-col cols="12" sm="6" lg="2">
            <table-filter
              v-model="filters.state"
              class="pt-0 table-filter"
              :items="states"
              label="dashboardHistoryComponents.assessmentHistoryTable.labels.status"
              chips
              :checkBox="false"
            />
          </v-col>
          <v-col cols="12" sm="6" lg="2">
            <tag-filter class="pt-0 table-filter" v-model="filters.tag" />
          </v-col>
          <v-col cols="12" sm="6" lg="2" class="date-picker">
            <date-picker v-model="filters.date" />
          </v-col>
          <v-col cols="12" sm="6" lg="4">
            <v-select
              v-model="advancedFilter"
              :items="advancedOptions"
              :label="
                $t(
                  'dashboardHistoryComponents.userHistoryTable.advancedSearch.filter'
                )
              "
              @change="applyAdvancedSearch"
              class="pt-0"
              clearable
              hide-details
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  Locations,
  States,
  AssessmentStateCodes
} from "./assessmentHistoryConstants.js";
import TableFilter from "./TableFilter.vue";
import TagFilter from "./TagFilter.vue";
import DatePicker from "./DatePicker.vue";
import { getTeamTags } from "@/customApi";
import { mapGetters } from "vuex";
import { HISTORY_FILTERS } from "@/components/common/assessment-history/user-history-filters.js";

export default {
  name: "FilterBar",
  components: {
    TableFilter,
    TagFilter,
    DatePicker
  },
  props: {
    users: Array,
    preloadedEmail: String
  },
  data() {
    return {
      assessments: this.value,
      showFilterBar: false,
      filters: {
        location: [],
        state: [],
        tag: [],
        date: [],
        search: "",
        showArchived: false
      },
      teamTags: [],
      locations: Locations,
      advancedFilter: null,
      advancedOptions: [
        {
          text: this.$t(
            "dashboardHistoryComponents.userHistoryTable.advancedSearch.optionText.noSentDeskAssessment"
          ),
          value: HISTORY_FILTERS.NO_SENT_DESK_ASSESSMENT
        },
        {
          text: this.$t(
            "dashboardHistoryComponents.userHistoryTable.advancedSearch.optionText.noCompletedDeskAssessment"
          ),
          value: HISTORY_FILTERS.NO_COMPLETED_DESK_ASSESSMENT
        },
        {
          text: this.$t(
            "dashboardHistoryComponents.userHistoryTable.advancedSearch.optionText.noHomeAssessment"
          ),
          value: HISTORY_FILTERS.NO_HOME_ASSESSMENT
        },
        {
          text: this.$t(
            "dashboardHistoryComponents.userHistoryTable.advancedSearch.optionText.noOfficeAssessment"
          ),
          value: HISTORY_FILTERS.NO_OFFICE_ASSESSMENT
        },
        {
          text: this.$t(
            "dashboardHistoryComponents.userHistoryTable.advancedSearch.optionText.homeAndOfficeAssessment"
          ),
          value: HISTORY_FILTERS.HOME_AND_OFFICE_ASSESSMENT
        },
        {
          text: this.$t(
            "dashboardHistoryComponents.userHistoryTable.advancedSearch.optionText.hasHealthCondition"
          ),
          value: HISTORY_FILTERS.HAS_HEALTH_CONDITION
        },
        {
          text: this.$t(
            "dashboardHistoryComponents.userHistoryTable.advancedSearch.optionText.needsAttention"
          ),
          value: HISTORY_FILTERS.NEEDS_ATTENTION
        },
        {
          text: this.$t(
            "dashboardHistoryComponents.userHistoryTable.advancedSearch.optionText.needsAttention30Days"
          ),
          value: HISTORY_FILTERS.NEEDS_ATTENTION_LAST_30_DAYS
        }
      ],
      initialised: false,
      previousFilterState: null
    };
  },
  watch: {
    advancedFilter: function (val) {
      this.$emit("isAdvancedSearch", !!val);
    },
    filters: {
      handler() {
        this.emitFilters();
      },
      deep: true
    },
    preloadedEmail(newVal, oldVal) {
      if (newVal) {
        // Reset table to ensure user can be found
        // TODO: Open preloaded assessments without need of history table
        this.clearFilters();
        this.filters.search = newVal;
      }
      if (oldVal && !newVal) {
        this.filters.search = newVal;
      }
    },
    users(newValue) {
      // We default the showArchived value to false, which means that filtering is required when the data first loads
      if (!this.initialised) {
        if (newValue.length) {
          this.emitFilters();

          this.initialised = true;
        }
      }
    },
    dashboardState: {
      handler(newState) {
        if (newState.activeFilter) {
          this.advancedFilter = newState.activeFilter;
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters("adminDashboard", ["dashboardState"]),
    tags() {
      let tagNames = this.teamTags.map(x => {
        return { text: x.name, value: x.name };
      });
      return [...tagNames, { value: "No Tag", text: "" }];
    },
    states() {
      return Object.values(States).filter(
        x => x.value < AssessmentStateCodes.FailedToSendInvitation
      );
    }
  },
  methods: {
    emitFilters() {
      this.$emit("update", this.getFiltersToEmit(), this.advancedFilter);
    },
    applyAdvancedSearch() {
      const advancedSearchOptions = [
        HISTORY_FILTERS.NO_HOME_ASSESSMENT,
        HISTORY_FILTERS.NO_OFFICE_ASSESSMENT,
        HISTORY_FILTERS.HOME_AND_OFFICE_ASSESSMENT,
        HISTORY_FILTERS.NO_COMPLETED_DESK_ASSESSMENT,
        HISTORY_FILTERS.NO_SENT_DESK_ASSESSMENT,
        HISTORY_FILTERS.HAS_HEALTH_CONDITION,
        HISTORY_FILTERS.NEEDS_ATTENTION,
        HISTORY_FILTERS.NEEDS_ATTENTION_LAST_30_DAYS
      ];

      if (advancedSearchOptions.includes(this.advancedFilter)) {
        this.$emit("update", this.getFiltersToEmit(), this.advancedFilter);
      } else {
        this.clearAdvancedFilter();
      }

      this.$mixpanel.track(`Advanced search for ${this.advancedFilter}`);
    },
    clearAdvancedFilter() {
      this.advancedFilter = null;
      this.$emit("noFilter");
    },
    clearFilters() {
      if (this.advancedFilter) {
        this.clearAdvancedFilter();
      }
      this.filters.location.splice(0);
      this.filters.state.splice(0);
      this.filters.tag.splice(0);
      this.filters.date.splice(0);
      this.filters.search = "";
      this.filters.showArchived = false;
    },
    async getTeamTags() {
      try {
        let tags = await getTeamTags({
          everythingTag: false
        });
        this.teamTags = tags;
      } catch (err) {
        this.teamTags = [];
      }
    },
    onShowArchivedChange() {
      this.$mixpanel.track("User history table filtered", {
        archived: this.filters.showArchived ? "On" : "Off"
      });
    },
    getFiltersToEmit() {
      let filtersToEmit = null;

      if (
        this.filters.location.length ||
        this.filters.state.length ||
        this.filters.tag.length ||
        this.filters.date.length ||
        this.filters.search.length ||
        !this.filters.showArchived
      ) {
        filtersToEmit = {
          location: this.filters.location.map(x => x.value),
          state: this.filters.state.map(x => x.value),
          tag: this.filters.tag.map(x => x.value),
          date: this.filters.date,
          search: this.filters.search,
          showArchived: this.filters.showArchived
        };
      }

      return filtersToEmit;
    },
    reset() {
      this.clearFilters();
      this.emitFilters();
    }
  }
};
</script>

<style scoped lang="scss">
@keyframes expand300px {
  from {
    width: 0px;
  }
  to {
    width: 300px;
  }
}

.aligned-icon {
  margin-top: -6px;
}

.advanced-mode-switch {
  ::v-deep label {
    color: var(--v-vflPrimary-base);
    white-space: nowrap;
  }
}

.archived-checkbox {
  // The `color` prop on `v-checkbox` doesn't seem to work, so styling manually instead
  ::v-deep {
    .v-icon,
    label {
      color: var(--v-vflText-base) !important;
    }

    label {
      white-space: nowrap;
    }
  }

  &.active {
    ::v-deep {
      .v-icon {
        color: var(--v-vflPrimary-base) !important;
      }
    }
  }
}

.table-filter {
  max-width: initial !important;
}

.date-picker {
  ::v-deep .v-input {
    max-width: initial !important;
  }
}
</style>
