<template>
  <div>
    <v-hover v-slot="{ hover }" open-delay="200">
      <div class="label-container" :class="{ 'right-aligned': isMobile }">
        <div
          :class="{
            'overflow-ellipsis': !hover,
            'overflow-visible': hover,
            'black--text': title,
            'text-body-1': title,
            'text-caption': !title
          }"
        >
          {{ label }}
        </div>
        <pre-existing-conditions-flag
          v-if="
            enablePreExistingConditions &&
            !hideConditionsFlag &&
            conditions.length > 0
          "
          class="ml-2"
          :name="label"
          :conditions="conditions"
          :userId="userId"
          openedFrom="User Table"
        />
      </div>
    </v-hover>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PreExistingConditionsFlag from "@/components/common/pre-existing-conditions/PreExistingConditionsFlag.vue";

export default {
  name: "user-contions-label",
  props: {
    label: String,
    title: Boolean,
    hideConditionsFlag: Boolean,
    conditions: Array,
    userId: String
  },
  components: {
    PreExistingConditionsFlag
  },
  computed: {
    ...mapGetters(["enablePreExistingConditions"]),
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    }
  }
};
</script>

<style scoped>
.overflow-visible {
  overflow: visible;
  white-space: normal;
  word-break: break-all;
}
.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.label-container {
  display: flex;
  align-items: flex-start;
}
.right-aligned {
  justify-content: flex-end;
}
</style>
