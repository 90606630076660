<template>
  <div>
    <vfl-tooltip top openOnClick :openOnHover="false" ref="tooltip">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="hover-icon"
          v-on="on"
          v-bind="attrs"
          icon
          max-height="20"
          max-width="20"
          :ripple="false"
          plain
          @click.native="ensurePropogations"
        >
          <img
            src="/images/icons/flagIcon.svg"
            width="20"
            class="icon default-icon"
          />
          <img
            src="/images/icons/flagIconHover.svg"
            width="20"
            class="icon hover-icon-image"
          />
        </v-btn>
      </template>
      <template v-slot:content>
        <user-conditions
          v-if="!showDialog"
          :conditions="conditions"
          @openDialog="openDialog(conditions)"
        />
      </template>
    </vfl-tooltip>

    <v-dialog
      v-if="showDialog"
      v-model="showDialog"
      max-width="80ch"
      :fullscreen="isMobile"
    >
      <user-conditions-dialog
        :conditions="conditions"
        @close="showDialog = false"
        :name="name"
        :email="email"
      />
    </v-dialog>
  </div>
</template>

<script>
import UserConditions from "@/components/common/pre-existing-conditions/UserConditions.vue";
import UserConditionsDialog from "@/components/common/pre-existing-conditions/UserConditionsDialog.vue";
import { track } from "@/customApi.js";

export default {
  name: "PreExistingConditionsFlag",
  props: {
    conditions: Array,
    name: String,
    email: String,
    userId: String,
    openedFrom: String
  },
  data() {
    return { showDialog: false };
  },
  components: {
    UserConditions,
    UserConditionsDialog
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    }
  },
  methods: {
    openDialog(conditions) {
      track("How to support dialog opened", {
        viewedUser: this.userId,
        openedFrom: this.openedFrom
      });

      this.$mixpanel.track("Pre Existing Conditions Admin", {
        action: "open dialog"
      });
      this.conditions = conditions;
      this.showDialog = true;
    },
    ensurePropogations(event) {
      this.$mixpanel.track("Pre Existing Conditions Admin", {
        action: "open tooltip"
      });
      event.stopPropagation = function () {};
    },
    showTooltip() {
      this.$refs.tooltip.showTooltip();
    }
  }
};
</script>

<style scoped>
>>> .v-dialog {
  box-shadow: none !important;
}
.icon {
  position: absolute;
}
.default-icon {
  opacity: 1;
}
.hover-icon-image {
  opacity: 0;
}
.hover-icon:hover .default-icon {
  opacity: 0;
}
.hover-icon:hover .hover-icon-image {
  opacity: 1;
}
</style>
