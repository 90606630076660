<template>
  <div class="d-flex align-center">
    <h5 class="text-subtitle-1" style="color: var(--v-vflTextDark-base)">
      {{ $t("mskOverview.title") }}
    </h5>
    <pain-overview-download-button class="ml-2" />
  </div>
</template>

<script>
import PainOverviewDownloadButton from "./PainOverviewDownloadButton.vue";

export default {
  name: "PainOverviewHeader",
  components: {
    PainOverviewDownloadButton
  }
};
</script>
