<template>
  <div class="overview-card pa-6 d-flex flex-column justify-space-between">
    <div class="d-flex justify-space-between">
      <pain-overview-donut-chart
        class="px-4"
        :loading="loading"
        :painData="painData"
        :isEnoughData="isEnoughData"
        :industryAverage="industryAverage"
      />
      <div class="d-flex flex-column" v-if="isEnoughData || loading">
        <pain-overview-assessed-people
          v-if="isEnoughData || loading"
          :loading="loading"
          :painData="painData"
          :totalPeopleAssessed="totalPeopleAssessed"
        />
        <pain-overview-non-typical-people
          v-if="!loading"
          :nonTypicalWorkers="nonTypicalWorkers"
          class="mt-auto"
        />
      </div>

      <pain-overview-no-data :count="totalPeopleAssessed" v-else />
    </div>
    <pain-overview-callout class="mt-8" v-if="!loading" />
  </div>
</template>

<script>
import PainOverviewDonutChart from "@/components/dashboard/cards/pain-overview/PainOverviewDonutChart.vue";
import PainOverviewCallout from "@/components/dashboard/cards/pain-overview/PainOverviewCallout.vue";
import PainOverviewAssessedPeople from "@/components/dashboard/cards/pain-overview/PainOverviewAssessedPeople.vue";
import PainOverviewNoData from "@/components/dashboard/cards/pain-overview/PainOverviewNoData.vue";
import { MINIMUM_PEOPLE_ASSESSED } from "@/components/dashboard/cards/pain-overview/constants.js";
import PainOverviewNonTypicalPeople from "@/components/dashboard/cards/pain-overview/PainOverviewNonTypicalPeople.vue";
import { mapGetters } from "vuex";

export default {
  name: "PainOverviewCard",
  components: {
    PainOverviewNonTypicalPeople,
    PainOverviewNoData,
    PainOverviewDonutChart,
    PainOverviewCallout,
    PainOverviewAssessedPeople
  },
  props: {
    loading: Boolean,
    nonTypicalWorkers: Array,
    painData: Object,
    industryAverage: Number
  },
  computed: {
    ...mapGetters(["isRestrictedAdminUser"]),
    isEnoughData() {
      if (this.isRestrictedAdminUser) return true;

      return this.totalPeopleAssessed >= MINIMUM_PEOPLE_ASSESSED;
    },
    totalPeopleAssessed() {
      return Object.values(this.painData).reduce(
        (sum, { count }) => sum + count,
        0
      );
    }
  }
};
</script>

<style scoped>
.overview-card {
  border: 1px solid var(--v-vflNeutralLight2-base);
  border-radius: 12px;
}
</style>
