<template>
  <div class="d-flex justify-space-between align-center flex-wrap">
    <div class="d-flex align-center">
      <h5 class="text-subtitle-1" style="color: var(--v-vflTextDark-base)">
        {{ $t("needsAttentionTable.title") }}
      </h5>
      <v-tooltip bottom color="transparent" maxWidth="250">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-on="on"
            v-bind="attrs"
            color="vflGreyLight"
            size="20"
            class="ml-1"
            >mdi-help-circle-outline</v-icon
          >
        </template>
        <vitrue-tooltip :message="$t('needsAttentionTable.tooltip')" />
      </v-tooltip>
      <span class="text-caption vflGreyLight--text ml-4">{{
        $t("needsAttentionTable.30days")
      }}</span>
    </div>
    <vfl-button
      v-if="rowCount"
      @click="seePeoplesInLast30DaysWithRisks"
      size="small"
      is-light
      >{{ seeAllText }}
    </vfl-button>
  </div>
</template>

<script>
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";
import { track } from "@/customApi.js";
import { HISTORY_FILTERS } from "@/components/common/assessment-history/user-history-filters.js";
import { mapGetters } from "vuex";
import VflButton from "@/components/vitrue-foundation-library/VflButton.vue";

export default {
  name: "WhoNeedsAttentionHeader",
  components: { VflButton, VitrueTooltip },
  props: {
    rowCount: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters({
      demoFeatures: "demoFeatures"
    }),
    seeAllText() {
      const count = this.rowCount > 10 ? "10+" : this.rowCount;
      return this.$t("needsAttentionTable.seeAll", { count: count });
    }
  },
  methods: {
    seePeoplesInLast30DaysWithRisks() {
      track("Who Needs Help", {
        info: "seePeoplesInLast30DaysWithRisks clicked"
      });
      this.updateDashboard();
    },
    updateDashboard() {
      const scrollToId = "user-history-table";
      const filter = this.demoFeatures
        ? HISTORY_FILTERS.NEEDS_ATTENTION
        : HISTORY_FILTERS.NEEDS_ATTENTION_LAST_30_DAYS;
      this.$store.dispatch("adminDashboard/triggerDashboardUpdate", {
        filter,
        scrollTargetId: scrollToId
      });
    }
  }
};
</script>
