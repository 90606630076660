export const adminDashboardStore = {
  namespaced: true,
  state: {
    activeFilter: null,
    scrollTargetId: null,
    basicFiltersOn: false,
    lastUpdate: 0,
    refreshTrigger: 0
  },
  mutations: {
    setActiveFilter(state, filterName) {
      state.activeFilter = filterName;
    },
    updateDashboard(state, { filter, scrollTarget, basicFilters }) {
      if (filter !== undefined) state.activeFilter = filter;
      if (scrollTarget !== undefined) state.scrollTargetId = scrollTarget;
      if (basicFilters !== undefined) state.basicFiltersOn = basicFilters;
      state.lastUpdate = Date.now();
    },
    triggerRefresh(state) {
      state.refreshTrigger += 1;
    }
  },
  actions: {
    setFilter({ commit }, filterName) {
      commit("setActiveFilter", filterName);
    },
    triggerDashboardUpdate({ commit }, { filter, scrollTargetId }) {
      commit("updateDashboard", {
        filter,
        scrollTarget: scrollTargetId,
        basicFilters: true
      });
    },
    refreshWhoNeedsAttention({ commit }) {
      commit("triggerRefresh");
    }
  },
  getters: {
    dashboardState: state => ({
      activeFilter: state.activeFilter,
      scrollTargetId: state.scrollTargetId,
      basicFiltersOn: state.basicFiltersOn,
      lastUpdate: state.lastUpdate,
      refreshTrigger: state.refreshTrigger
    }),
    isFilterActive: state => filterName => {
      return state.activeFilter === filterName;
    }
  }
};
