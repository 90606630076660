<template>
  <div id="pain-overview-container" class="d-flex flex-column">
    <pain-overview-header class="mb-4" />

    <vfl-alert
      v-if="error && !loading"
      :message="$t('mskOverview.error')"
      type="error"
      is-light
    />
    <pain-overview-card
      v-else
      class="flex-grow-1"
      :painData="painData"
      :nonTypicalWorkers="nonTypicalWorkers"
      :industryAverage="industryAverage"
      :loading="loading"
    />
  </div>
</template>

<script>
import PainOverviewHeader from "@/components/dashboard/cards/pain-overview/PainOverviewHeader.vue";
import PainOverviewCard from "@/components/dashboard/cards/pain-overview/PainOverviewCard.vue";
import VflAlert from "@/components/vitrue-foundation-library/VflAlert.vue";
import {
  getTeamPainDistribution,
  getPeopleWithUniqueRequirements
} from "@/api/teamApi.js";
import { mapPainData } from "@/components/dashboard/cards/pain-overview/pain-data-mapper";

export default {
  name: "HealthOverview",
  components: {
    PainOverviewHeader,
    PainOverviewCard,
    VflAlert
  },
  data() {
    return {
      loading: false,
      error: false,
      industryAverage: 0,
      painData: {},
      nonTypicalWorkers: []
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;

      try {
        const painDistribution = await getTeamPainDistribution();

        this.industryAverage = painDistribution.industryAverage;
        this.painData = mapPainData(painDistribution);
        this.nonTypicalWorkers = await getPeopleWithUniqueRequirements();
      } catch (ex) {
        this.$logger.captureException(ex);
        this.error = true;
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
