<template>
  <v-row
    :align="loading ? 'center' : 'start'"
    :class="loading ? '' : 'pt-8'"
    justify="center"
    class="fill-height"
    no-gutters
    :style="{
      minHeight: loading ? 'var(--app-content-height)' : ''
    }"
  >
    <loading-symbol
      :size="120"
      :color="$vuetify.theme.currentTheme.primary"
      v-if="loading"
    />

    <v-col v-if="!confirm && !loading" lg="4" md="6" sm="8" cols="10">
      <login-options
        :signUp="false"
        :enterpriseSSOLoginAvailable="showEnterpriseSSOLogin"
        :ssoIdpName="idpName"
        :ssoTeamId="teamId"
        :automaticSSOSignIn="automaticSSOSignIn"
        :isReturningToPartialAssessment="isReturningToPartialAssessment"
        @confirm="signInWithEmail($event)"
        @forgotPassword="$router.push('ResetPassword')"
        @completeSignIn="completeSignIn()"
        @confirmSignUp="redirectFromSignInToSignUp($event)"
      />
    </v-col>
    <v-col lg="4" md="6" sm="12" v-if="!loading && confirm">
      <confirm-code
        :email="email"
        :password="redirectPassword"
        @confirmed="userSignedUp()"
      />
    </v-col>
    <v-snackbar v-model="showErrorMessage" timeout="2500" color="red">
      {{ errorMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="showErrorMessage = false">
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="showSuccessMessage" timeout="2500" color="green">
      {{ successMessage }}
    </v-snackbar>
  </v-row>
</template>

<script>
import { Auth, Hub } from "aws-amplify";
import { mapMutations, mapGetters } from "vuex";
import {
  getUserDetails,
  createTeamMembersWithTeamId,
  startNewAssessment,
  currentUserIsEndUser,
  updateSSOTeamMember,
  userTeamMemberInformation,
  confirmEndUserSignUp,
  getTeamFeatures
} from "../../customApi";
import LoadingSymbol from "../common/loaders/LoadingSymbol.vue";
import LoginOptions from "./LoginOptions.vue";
import ConfirmCode from "./ConfirmCode.vue";
import TeamService from "@/services/teamService.js";
import { completeUserSignIn } from "@/services/authorization/post-auth-setup.js";
import { AuthException, isGuidValid } from "@/utils/auth-validation.js";
import { SSORoles } from "@/services/authorization/user-types.js";
import UserpoolService from "@/services/userpool/userpool-service.js";
import { RedirectPages } from "@/services/authorization/url-redirect/redirect-constants.js";
import { getSSOCustomisation } from "@/services/authorization/sso-customisation-service.js";
import { setUpRedirectActions } from "./loginActionService.js";
import { setUpRedirectPage } from "@/services/authorization/url-redirect/url-redirect-service.js";
import { getUrlSearchParameters } from "@/services/url-service.js";

export default {
  name: "Login",
  props: {
    msg: String,
    successMsg: String,
    errorMsg: {
      type: String,
      default: ""
    },
    isReturningToPartialAssessment: Boolean
  },
  data() {
    return {
      email: "",
      confirm: false,
      errorMessage: this.errorMsg,
      successMessage: this.successMsg,
      showErrorMessage: !!this.errorMsg,
      showSuccessMessage: !!this.successMsg,
      loading: true,
      origin: "",
      signUpRedirect: false,
      ssoEndUserSignedUp: false,
      teamId: null,
      idpName: null,
      code: null,
      teamMemberInfo: null,
      ssoSignOut: false,
      automaticSSOSignIn: false,
      redirectPassword: "",
      nonAuthRedirect: null,
      ssoNewAssessment: false,
      assessmentType: null
    };
  },
  components: {
    LoginOptions,
    ConfirmCode,
    LoadingSymbol
  },
  created() {
    this.updateFromSessionStorage();

    // If signed in already, user has been redirected after successful sign up
    if (this.signedIn) {
      this.completeSignIn();
      return;
    }

    if (this.nonAuthRedirect) {
      return;
    }

    // Federation sign in
    Hub.listen("auth", async ({ payload }) => {
      if (
        payload.event.includes("failure") &&
        !this.nonAuthRedirect &&
        !sessionStorage.getItem("nonAuthRedirect")
      ) {
        var ssoUserNameMisMatch = "Invalid+ProviderName%2FUsername+combination";
        if (
          payload.data?.message &&
          payload.data.message.includes(ssoUserNameMisMatch)
        ) {
          this.$logger.captureMessage(
            `SSO username mismatch for team: ${this.teamId}`,
            "error"
          );
        }
        sessionStorage.setItem("error", this.errorMessage);
        this.loading = false;
        this.signOut();
        return;
      }

      if (!this.code) {
        return;
      }

      if (payload.event == "signIn") {
        try {
          if (this.ssoEndUserSignedUp) {
            const user = await Auth.currentAuthenticatedUser();
            var endUserDto = {
              email: { value: user.attributes.email }
            };
            await confirmEndUserSignUp(endUserDto);
          }

          await UserpoolService.setUserpoolSettingsFromId(this.teamId);
          await this.checkAuthenticatedUser();
        } catch (err) {
          this.$logger.captureException(
            err,
            `Error when authenticating for team ${this.teamId}`
          );

          sessionStorage.setItem("originURL", this.origin);
          sessionStorage.setItem(
            "error",
            err instanceof AuthException
              ? err.message
              : this.$t("authentication.genericErrorMessage")
          );
          this.signOut();
        } finally {
          this.code = null;
          this.loading = false;
        }
        return;
      }
    });
  },
  computed: {
    ...mapGetters(["isSimpleUser", "signedIn", "redirectPage"]),
    highZoom() {
      return window.devicePixelRatio > 1.25;
    },
    simpleUserRedirect() {
      return !!this.origin?.includes("assessment");
    },
    showEnterpriseSSOLogin() {
      let paramString = window.location.href.split("?")[1];
      var idp = this.idpName;
      if (paramString) {
        paramString = paramString.replace("#/", "");
        let queryString = new URLSearchParams(paramString);
        idp = queryString.get("idp");
      }

      let ssoURL = getSSOCustomisation(idp) != null;

      return ssoURL || this.ssoSignOut;
    }
  },
  methods: {
    ...mapMutations([
      "setUserData",
      "setSSOUserData",
      "setTeamIdpName",
      "setTeamId",
      "setRedirectAssessment",
      "setRedirectCode",
      "setAdminAssessmentToView",
      "setRedirectPage",
      "setRedirectAction"
    ]),
    updateFromSessionStorage() {
      // If an origin url exists, a redirect to login has occured. If an error also exists,
      // a sign in has failed therefore redirect to the original url with an error. If not
      // redirected, check if user is already logged in.
      const searchParams = getUrlSearchParameters(window.location.href);
      if (searchParams) {
        this.code = searchParams.get("code");
      }
      this.origin = sessionStorage.getItem("originURL");
      this.signUpRedirect = sessionStorage.getItem("signUp") === "true";
      this.ssoEndUserSignedUp = !!sessionStorage.getItem("ssoEndUserSignedUp");
      let error = sessionStorage.getItem("error");
      if (error) {
        this.errorMessage = error;
        this.showErrorMessage = true;
      }
      this.teamId = sessionStorage.getItem("teamId");
      this.ssoNewAssessment = !!sessionStorage.getItem("newAssessment");
      this.idpName = sessionStorage.getItem("idpName");
      this.assessmentType = sessionStorage.getItem("assessmentType");
      this.ssoSignOut = !!sessionStorage.getItem("ssoSignOut");
      let reportAssessment = sessionStorage.getItem("reportAssessment");
      this.parseRedirectAssessment(reportAssessment);
      this.nonAuthRedirect = sessionStorage.getItem("nonAuthRedirect");
      var adminAssessmentToView = sessionStorage.getItem(
        "adminAssessmentToView"
      );
      if (adminAssessmentToView) {
        this.setAdminAssessmentToView(adminAssessmentToView);
      }
      setUpRedirectPage(searchParams);
      setUpRedirectActions(searchParams);
      sessionStorage.clear();

      if (this.teamId) {
        this.$logger.captureMessage(
          `updateFromSessionStorage - Retrieved teamId ${this.teamId} `,
          "info"
        );
      }

      if (this.origin) {
        if (error && this.simpleUserRedirect) {
          window.location.replace(this.origin + "?error=" + error);
        } else if (error) {
          if (this.signUpRedirect) {
            this.$router.push({
              name: "SignUp",
              params: {
                errorMsg: error
              }
            });
          } else {
            this.loading = false;
            this.errorMessage = error;
          }
        }
      } else if (!this.signedIn && (!this.code || this.nonAuthRedirect)) {
        this.findUser();
      }
    },
    async completeSignIn() {
      try {
        this.loading = true;
        await completeUserSignIn(this.$store, this.$router);
      } catch (err) {
        this.signOut();
        // If a simple user using an assessment link, send back to original url with error
        // otherwise, send to sign up with error
        if (this.simpleUserRedirect) {
          window.location.replace(this.origin + "?error=" + err.message);
        } else {
          this.$router.push({
            name: "SignUp",
            params: {
              errorMsg: err.message
            }
          });
        }
      } finally {
        this.loading = false;
      }
    },
    async checkAuthenticatedUser() {
      let currentUser = await Auth.currentAuthenticatedUser();
      this.teamMemberInfo = await userTeamMemberInformation();
      // If email of federated user does not match assessment email, throw
      // error to redirect back to original assessment url
      if (this.simpleUserRedirect) {
        let assessmentId = this.origin.split("/").pop();
        let simpleUser = await getUserDetails(assessmentId);
        if (
          simpleUser.email.value.toLowerCase() !=
          currentUser.attributes.email.toLowerCase()
        ) {
          throw new AuthException(this.$t("authentication.invalidEmailMatch"));
        }
        currentUser = await Auth.currentAuthenticatedUser();
      } else if (this.teamId) {
        if (!isGuidValid(this.teamId)) {
          throw new AuthException(this.$t("authentication.invalidTeamId"));
        }
        if (!this.teamMemberInfo.teamMemberId) {
          var teamFeatures = await getTeamFeatures(this.teamId);
          if (
            teamFeatures.featureIds.some(
              feature => feature === "NoJitProvisioning"
            ) &&
            !this.teamMemberInfo.endUserId
          ) {
            throw new AuthException(
              this.$t("authentication.failedToAutoJoinTeam")
            );
          }
          await this.configureSSOUser(currentUser);
          this.teamMemberInfo = await userTeamMemberInformation();
        } else {
          await updateSSOTeamMember();
        }
      }

      this.setTeamIdpName(this.idpName);
      this.setTeamId(this.teamId);

      if (this.idpName) {
        this.setSSOUserData({
          ...currentUser,
          ...this.teamMemberInfo
        });
      } else {
        this.setUserData(this.teamMemberInfo);
      }
      await completeUserSignIn(this.$store, this.$router);
    },
    async configureSSOUser(currentUser) {
      let userType = currentUser.attributes["custom:userType"];
      let email = currentUser.attributes.email;
      let userName = currentUser.username;
      if (userType in SSORoles && SSORoles[userType] !== SSORoles.VIDAUser) {
        await this.createSSOTeamMember(userName, email, userType);
      } else {
        let userExists = await currentUserIsEndUser();
        if (!userExists || this.ssoNewAssessment) {
          // old flow where we create an assessment for non existing end users or if sso new assessment parameter set
          // in this sccenario we need to remove redirect page and action to avoid creating new assessments multiple times.
          if (this.redirectPage === RedirectPages.DESK_ASSESSMENT) {
            this.setRedirectPage(null);
            this.setRedirectAction(null);
          }
          await this.startNewAssessment(email);
        }
      }
    },
    async startNewAssessment(email) {
      let invitation = {
        email: { value: email },
        assessmentType: this.assessmentType ?? "Desk assessment",
        SSOUser: true
      };
      let assessmentId = await startNewAssessment(invitation, this.teamId);
      let assessment = {
        assessmentType: this.assessmentType ?? "Desk assessment",
        id: assessmentId
      };
      this.parseRedirectAssessment(JSON.stringify(assessment));
    },
    async createSSOTeamMember(userName, email, userType) {
      let role =
        SSORoles[userType] === SSORoles.VIDACompanyAdmin
          ? "SuperAdmin"
          : "Admin";
      if (!this.teamMemberInfo.teamMemberId) {
        let teamMember = {
          userName: userName,
          email: { value: email },
          role: role,
          ssoSignIn: true,
          inviteMember: false
        };
        await createTeamMembersWithTeamId(this.teamId, [teamMember]);
      }
    },
    userSignedUp() {
      this.successMessage = this.$t("authentication.signUpComplete");
      this.confirm = false;
    },
    signOut() {
      this.loading = false;
      this.setUserData(null);
      if (this.idpName && this.teamId) {
        sessionStorage.setItem("idpName", this.idpName);
        sessionStorage.setItem("teamId", this.teamId);
      }
      Auth.signOut();
    },
    async findUser() {
      try {
        this.loading = true;
        await UserpoolService.setUserpoolSettingsFromId(this.teamId);
        const user = await Auth.currentAuthenticatedUser();
        if (!this.teamMemberId) {
          this.teamMemberInfo = await TeamService.getTeamMemberInfo();
        }
        this.setUserData(this.teamMemberInfo);

        if (this.nonAuthRedirect) {
          this.setRedirectCode(this.code);
          this.$router.push(this.nonAuthRedirect, () => {});
          return;
        }
        await completeUserSignIn(this.$store, this.$router);
        this.loading = false;
      } catch (err) {
        let error = err.message ?? err;
        if (!error.includes("authenticated")) {
          this.$logger.captureException(error);
          sessionStorage.setItem("originURL", this.origin);
          sessionStorage.setItem("error", error);
          this.signOut();
        } else if (this.nonAuthRedirect) {
          // for users who are not authorized but sharing slack
          this.setRedirectCode(this.code);
          this.$router.push(this.nonAuthRedirect, () => {});
        }
        this.loading = false;
      }
    },
    async redirectFromSignInToSignUp(user) {
      this.confirm = true;
      this.email = user.email;
      this.redirectPassword = user.password;
      await Auth.resendSignUp(this.email);
    },
    parseRedirectAssessment(reportAssessment) {
      if (!reportAssessment) {
        return;
      }
      try {
        let assessment = JSON.parse(reportAssessment);
        this.setRedirectAssessment(assessment);
      } catch (err) {
        this.$logger.captureException(err);
      }
    }
  }
};
</script>
