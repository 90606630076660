<template>
  <div class="d-flex">
    <v-menu bottom offset-x absolute nudge-bottom="24px">
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          dot
          :value="newFeatureInAccountMenu"
          color="vitrueRed"
          offset-y="20"
          offset-x="-4"
        >
          <v-row v-bind="attrs" v-on="on" no-gutters align="center">
            <v-col cols="auto" class="d-flex">
              <v-badge
                dot
                :value="newFeatureInAccountMenu"
                color="vitrueRed"
                offset-x="11"
                offset-y="5"
                overlap
              >
                <avatar-icon
                  :text="userName"
                  :fallback-text="userEmail"
                  :size="32"
                />
              </v-badge>
            </v-col>
            <span class="d-sr-only">Open account menu</span>
          </v-row>
        </v-badge>
      </template>
      <v-list dense class="py-0">
        <div v-for="item in accountMenuItems" :key="item.key">
          <v-list-item v-if="!item.hide" @click="dropDownItemClicked(item)">
            <v-list-item-title v-if="!item.hide"
              ><v-icon class="pb-1 mr-2">{{ item.icon }}</v-icon
              ><v-badge
                :value="item.newFeature"
                dot
                right
                offset-x="-4"
                color="vitrueRed"
                ><span>{{ item.title }}</span></v-badge
              ></v-list-item-title
            >
          </v-list-item>
        </div>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NewFeatureService from "@/services/new-feature-service.js";
import { getSSOCustomisation } from "@/services/authorization/sso-customisation-service.js";
import RestApiClient from "@/infrastructure/restApiClient.js";
import { Auth } from "aws-amplify";
import AvatarIcon from "@/components/common/AvatarIcon.vue";
import { track } from "@/customApi.js";

export default {
  name: "AccountMenu",
  components: { AvatarIcon },
  computed: {
    ...mapGetters([
      "isSuperAdmin",
      "teamId",
      "teamIdpName",
      "userName",
      "userEmail"
    ]),
    accountMenuItems() {
      return [
        {
          key: "Settings",
          icon: "mdi-cog-outline",
          title: this.$t("layout.sidebar.settings"),
          path: "/settings",
          newFeature: this.showCustomRecommendationsFeature
        },
        {
          hide: !this.isSuperAdmin,
          key: "Subscription",
          icon: "mdi-account-circle",
          title: this.$t("layout.sidebar.subscription"),
          path: "/subscription",
          newFeature: false
        },
        {
          key: "Help",
          icon: "help_outline",
          title: this.$t("layout.sidebar.help"),
          newFeature: false
        },
        {
          key: "Logout",
          icon: "exit_to_app",
          title: this.$t("layout.sidebar.logout"),
          newFeature: false
        }
      ];
    },
    showCustomRecommendationsFeature() {
      return NewFeatureService.showFeature("CustomRecommendations");
    },
    newFeatureInAccountMenu() {
      return this.accountMenuItems.filter(x => x.newFeature).length > 0;
    }
  },
  methods: {
    ...mapActions(["resetLoginState"]),
    dropDownItemClicked(item) {
      if (item.key === "Logout") {
        this.signOut();
        return;
      }

      if (item.key === "Help") {
        this.openHelpPage();
        return;
      }

      this.navigate(item);
    },
    signOut() {
      const enterpriseSSO = getSSOCustomisation(this.teamIdpName) != null;

      if (this.ssoSignIn && enterpriseSSO) {
        sessionStorage.setItem("ssoSignOut", "true");
        sessionStorage.setItem("teamId", this.teamId);
        sessionStorage.setItem("idpName", this.teamIdpName);
      }

      localStorage.removeItem(RestApiClient.sessionHeaderKey);

      Auth.signOut().then(() => {
        this.resetLoginState();
        this.$router.push("/", () => {});
      });
    },
    openHelpPage() {
      this.$gtag.event("Open help page", {
        event_category: "Sidebar"
      });

      window.open("https://www.vitrueremote.com/faqs", "_blank");
    },
    navigate(item) {
      if (!item.path) {
        return;
      }

      this.$mixpanel.track(`New side bar navigation`, {
        route: item.path
      });
      track("New side bar navigation", {
        route: item.path
      });

      this.$router.push(item.path, () => {});
      this.$vuetify.goTo(0);
    }
  }
};
</script>

<style lang="scss" scoped></style>
